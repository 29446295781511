import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

const STORAGE_KEY = {
  "token": "token",
  "mode": "mode"
}

const initial = {
  profile: {},
  token: localStorage.getItem(STORAGE_KEY.token) || null
}

const store = createContext<any>(initial)

export function StoreProvider({ children }: PropsWithChildren){
  const [mode, setMode] = useState(localStorage.getItem(STORAGE_KEY.mode) || "light")
  const [rate, setRate] = useState(0)
  const [state, setState ] = useState(initial)

  function set(key: string, value: any){
    setState(s => ({
      ...s, 
      [key]: {...s[key], ...value}
    }))
  }

  function setToken(token: string){
    if(token === null){
      localStorage.removeItem(STORAGE_KEY.token)
    }
    else localStorage.setItem(STORAGE_KEY.token, token)
    setState(s => ({ ...s, token }))
  }

  function toggleMode(){
    setMode(p => {
      if(p === "light") return "dark";
      else return "light"
    })
  }  

  useEffect(() => {
    const controller: any = document.getElementById('theme-opt')
    if(mode === 'light'){
      controller.href = '/assets/css/style.min.css'
      localStorage.setItem(STORAGE_KEY.mode, "light")
    }
    else {
      controller.href = '/assets/css/style-dark.min.css';
      localStorage.setItem(STORAGE_KEY.mode, "dark")
    };

  }, [mode])
  return(
    <store.Provider value={{...state, rate, setRate, mode, toggleMode, set, setToken}}>{ children }</store.Provider>
  )
}

export const useStore = () => useContext(store)
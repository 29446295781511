import { Header, Footer, Nav } from "../../components";
import NFTsByCategories from "./nfts-by-categories";
import Slides from "./slides";

export function Home(){
  return(
    <div style={{ overflowX: "hidden"}}>
      <Header />
      {/* <div className="position-relative" style={{ backgroundImage: `url(${"/assets/images/category/art-cover.jpg"})`, width: "100%", height: 450 }} >
        <div className="position-absolute top-0 end-0 start-0" style={{ width: "100%", paddingTop: 80, height: '100%', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255,255,255,1))'}}>
          <Nav style="dark" active="all" />
          <Slides />
        </div>
      </div> */}
      <div style={{ marginTop: 90 }}><Nav active="all" style="dark" /></div>
      
      <Slides />
      <NFTsByCategories title="Trending in Arts" link="/explore/art" filters={{category: "art"}} />
      <NFTsByCategories title="Trending in Games" link="/explore/games" filters={{category: "gaming"}} />
      <NFTsByCategories title="Membership" link="/explore/membership" filters={{category: "membership"}} />
      <NFTsByCategories title="Trending in PFPs" link="/explore/pfps" filters={{category: "pfps"}} />
      <NFTsByCategories title="Photography" link="/explore/photography" filters={{category: "photography"}} />
      <NFTsByCategories title="Others" link="/explore/other" filters={{category: "other"}} />
      <Footer />
    </div>
  )
}
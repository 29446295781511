import { useNavigate } from "react-router-dom";
import { Button, Empty, INFT, NFTSkeleton } from "../../components";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { useEffect, useState } from "react";
import { Pagination, Skeleton } from "@mui/material";
import { Selectinput, Textarea, Textinput2 } from "../../components/textinput";
import { useFormik } from "formik";
import { useStore } from "../../hooks/use-store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty } from "../../utilities/helpers";
import numeral from "numeral";
import * as yup from 'yup'
import { categories } from "../../utilities/constant";
import useCopy from "../../hooks/use-copy";

export default function Collection(){
  const { profile } = useStore()
  const [selected, setSelected] = useState({})
  const [page, setPage] = useState(1)
  const { makeRequest, data, setData, loading } = useRequest(api.collection)
  const navigate = useNavigate()
  const [_, handleCopy] = useCopy()
  const url = process.env.REACT_APP_URL + "/creator/" + profile?.username;

  useEffect(() => {
    makeRequest({ page }, "get")
  }, [ page ])
  return(
    <div>
      <div className="d-flex align-items-center mb-2 justify-content-between">
      <h3 className="mb-2">Collection</h3>
      {
        profile?.username &&
        <div>
          <small className="d-lg-block d-none text-muted px-2 py-1 rounded-lg shadow">{ url } 
          <a  onClick={() => handleCopy(url, {success: "Creator's link copied successfully"})} className="text-primary h5 ms-1">
            <i className="uil uil-copy"></i></a>
          </small>
          <a  onClick={() => handleCopy(url, {success: "Creator's link copied successfully"})} className="d-lg-none btn btn-icon btn-pills btn-primary">
            <i className="uil uil-share-alt"></i>
          </a>
        </div>
      }
      </div>
      <NFTItem item={selected} setData={setData} />
      {
        loading ?
        <div className="responsive-grid mt-3 collection" id="grid">
          {[1,2,3].map(i => <div className="me-1" key={i}> <NFTSkeleton style={{width: "100%"}} /> </div>)}
        </div>:
        data?.data?.totalDocs === 0 ?
        <Empty 
          title="Empty collection"
          subtitle={
          <>
            We'll keep your collection here for you to revisit anytime.
            <div className="mt-2">
              <Button title="Mint NFT" onClick={() => navigate("/dashboard/collection")} />
            </div>
          </>} 
        /> :
        <div className="responsive-grid mt-3 collection" id="grid">
          { 
            data?.data?.docs.map((n, i) => (
              <div className="col" key={i} onClick={() => setSelected(n)} data-bs-toggle="modal" data-bs-target="#collection">
                <Nft {...n} />
              </div>
            )) 
          }
        </div>
      }
      <div className="d-flex justify-content-end">
        <Pagination 
          count={data?.data?.totalPages}
          page={page || -1} variant="outlined"
          onChange={(_, value) => setPage(value)}
          color="primary" shape="rounded"
          className="pagination lc-pagination gap-2"
        />
      </div>
    </div>
  )
}

function NFTItem({ item, setData }){
  const formik = useFormik({
    initialValues: item,
    validationSchema: yup.object({
      is_listed: yup.boolean().transform((v) => Boolean(v))
    }),
    async onSubmit(value){
      console.log(value)
      const { data, status } = await makeRequest(value, 'put')
      if(status === "success"){
        setData(s => ({
          ...s, data: {
            ...s.data,
            docs: s.data.docs.map(d => d._id === item?._id ? data : d )
          }
        }))
      }
    }
  })

  const { makeRequest, loading } = useRequest(api.collection + formik?.values?._id)

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div  className="modal fade" id="collection" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">{ item?.name }</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal">
              <i className="uil uil-times fs-4"></i>
            </button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <div className="border mx-auto rounded" style={{width: 140, padding: 5}}>
                  <img src={item?.image} className="rounded" style={{width: "100%", objectFit: "cover"}} />
                </div>
                <Textinput2
                  label="Name" name="name"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Price" name="price"
                  type="text" 
                  formik={formik}
                />
                <Selectinput
                  label="Is Listed" name="is_listed"
                  data={["list", "unlist"].map(c => ({label: c.toLocaleUpperCase(), value: String(c === "list") }))}
                  formik={formik}
                />

                <Selectinput
                  label="Category" name="category"
                  type="text"
                  data={Object.entries(categories).map(c => ({label: c[1], value: c[0] }))}
                  formik={formik}
                />
                <Textinput2
                  label="Status" name="status"
                  type="text" disabled
                  formik={formik}
                />
                <Textarea
                  label="Description" name="description"
                  type="text" 
                  formik={formik}
                />

              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Update Art" />
          </div>
        </form>
      </div>
    </div>
  )
}

export function Nft({ image, name, user, price }: INFT){
  const { rate } = useStore()
  return(
    <div className="card bg-white nft-items nft-primary rounded-md shadow overflow-hidden mb-1">
      <div className="nft-image position-relative overflow-hidden">
        <LazyLoadImage 
          placeholder={<Skeleton variant="rounded" sx={{ width: "100%", height: 250, minHeight: 200 }} />} 
          src={image} style={{ width: "100%", minWidth: "50%", height: 280, minHeight: 200, objectFit: 'cover'}} alt=""
        />
      </div>

      <div style={{padding: 22}} className="card-body content position-relative">
        {
          !isEmpty(user?.avatar) &&
          <div className="img-group">
            <a className="user-avatar">
              <img src={user?.avatar} alt="user" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-md rounded-circle"/>
            </a>
          </div>
        }

        <div className="">
          <a  className="title text-dark h6">{name}</a>
          <div />
          <div className="d-flex flex-wrap align-items-center mt-2">
            <small className="rate fw-bold">{ price } ETH</small>
            <small style={{fontSize: 12}} className="text-dark mz fw-bold">(${ numeral(price).multiply(Number(rate)).format("0,0.00")})</small>
          </div>
        </div>
      </div>
    </div>
  )
}
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRequest } from "../hooks/use-request";
import api from "../utilities/api";
import { useStore } from "../hooks/use-store";
import { isEmpty, maskAddress } from "../utilities/helpers";
import useCopy from "../hooks/use-copy";
import useSidebar from "../hooks/use-sidebar";
import { Search } from "./search";
import numeral from "numeral";

export function Header(){
  const { set, token, mode, setRate, toggleMode, profile, setToken } = useStore()
  const { makeRequest} = useRequest(api.profile)
  const { makeRequest: getRate } = useRequest(api.rates)
  const navigate = useNavigate()
  const [copied, handleCopy] = useCopy()
  const sidebarlist = useSidebar()

  function logout(){
    setToken(null)
    toast("Logout successful", { type: "success" })
    navigate("/auth/login")
  }

  useEffect(() => {
    if(token && isEmpty(profile)) {
      makeRequest({ }, "get")
        .then(({ data, status }) => {
          if(data && status === "success") {
            set("profile", data);
          }
        })
        .catch(console.log);
    }

    getRate({}, "get").then(data => setRate(data?.data?.rate))
  }, [])

  return(
    <header id="topnav" className="defaultscroll sticky">
      <div className="container d-flex align-items-center justify-content-between">
        <Link className="logo d-flex" to="/">
          <img src="/assets/images/icon-logo-64.png" height="45" alt="" />
          <h4 style={{marginLeft: 15}} className="h3 fw-bold mb-0 text-gradient-primary">Artverse.</h4>
        </Link>
        <div className="menu-extras">

        </div>
        <ul className="buy-button list-inline mb-0">
          <li className="list-inline-item mb-0 me-1">
            {/* <div className="dropdown">
              <button type="button" className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {/* <i className="uil uil-search text-dark title-dark btn-icon-light fs-5 align-middle"></i>
              <i className="uil uil-search text-dark fs-5 align-middle"></i>
              </button>
              <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0" style={{width: 300}}>
                <div className="search-bar">
                  <div id="itemSearch" className="menu-search mb-0">
                    <form role="search" method="get" id="searchItemform" className="searchform">
                      <input type="text" className="form-control border rounded" name="s" id="searchItem" placeholder="Search..." />
                      <input type="submit" id="searchItemsubmit" value="Search" />
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
            <Search />
          </li>
          <button onClick={toggleMode} type="button" className="btn p-0 mx-2">
            {
              mode === "light" ?
              <i className="uil uil-sun text-dark fs-5 align-middle"></i>:
              <i className="uil uil-moon text-dark fs-5 align-middle"></i>
            } 
          </button>
          
          {
            isEmpty(token) ?
            <li className="list-inline-item mb-0 me-1">
              <Link to="/auth/login" className="btn btn-icon btn-pills btn-primary">
                <i className="uil uil-signin fs-6"></i>
              </Link>
            </li>:
            <li className="list-inline-item mb-0">
              <div className="dropdown dropdown-primary">
                <button type="button" className="btn btn-pills dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src={profile?.avatar} className="rounded-pill avatar avatar-sm-sm" alt="" />
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded" style={{minWidth: 200}}>
                    <div className="position-relative">
                        <div className="pt-5 pb-3 bg-gradient-primary"></div>
                        <div className="px-3">
                            <div className="d-flex align-items-end mt-n4">
                                <img 
                                  src={profile?.avatar} 
                                  className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md" alt="" 
                                />
                                <h6 className="text-dark fw-bold mb-0 ms-1">{profile?.fullname || profile?.username }</h6>
                            </div>
                            {/* <div className="mt-2">
                                <small className="text-start text-dark d-block fw-bold">Wallet:</small>
                                <div className="d-flex justify-content-between align-items-center">
                                    <small id="myPublicAddress" className="text-muted">{maskAddress(profile?.address)}</small>
                                    <span
                                      onClick={() => handleCopy(profile?.address, { success: "Address copied successfully" })} 
                                      className="text-primary"
                                    >
                                        <span className="uil uil-copy"></span>
                                    </span>
                                </div>
                            </div> */}
                            
                            <div className="mt-2">
                                <small className="text-dark">Balance: <span className="text-primary fw-bold"> { numeral(profile?.balance).format("0.000000") } ETH</span></small>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                      {
                        sidebarlist.map((s, i) => (
                            <Link key={i} className="dropdown-item small fw-semibold text-dark d-flex align-items-center mt-2" to={s.to}>
                              <span className="mb-0 d-inline-block me-1">{ s.icon }</span> { s.title }
                            </Link>
                          ))
                      }
                      <div className="dropdown-divider border-top"></div>
                      <a onClick={logout} className="dropdown-item small fw-semibold text-dark d-flex align-items-center">
                        <span className="mb-0 d-inline-block me-1">
                          <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
                        </span> 
                        Logout
                      </a>
                    </div>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>
    </header>
  )
}
import { Skeleton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { Link } from "react-router-dom";

function shuffleArray(array: any[]) {
  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

function splitAndRandomize(array: any[]) {
  const mid = Math.floor(array.length / 2);

  // Split the array into two sections
  const section1 = array.slice(0, mid);
  const section2 = array.slice(mid);

  // Randomize each section
  const randomizedSection1 = shuffleArray(section1);
  const randomizedSection2 = shuffleArray(section2);

  return [randomizedSection1, randomizedSection2];
}



export default function Slides(){
  const [section1, setSection1] = useState([]);
  const [section2, setSection2] = useState([]);
  const { makeRequest, loading, data } = useRequest(api.nfts)

  useEffect(() => {
    makeRequest({ page: 1, limit: 30}, "get") 
      .then(data => {
        const [randomizedSection1, randomizedSection2] = splitAndRandomize(data.data?.docs);
        setSection1(randomizedSection1);
        setSection2(randomizedSection2);
      })
  }, [])

  return(
    <section className="bg-half-174">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="slider">
              <div className="slide-track">
                {
                 loading ? 
                 [1,2,3,4,5].map((s, i) => (
                   <div key={i} className="slide mx-2 mb-3">
                     <div>
                       < Skeleton variant="rounded" width={"100%"} height={220} />
                     </div>
                   </div>
                 )):
                  section1.map((s, i) => (
                    <div key={i} className="slide mx-2">
                      <div className="card bg-white nft-items nft-margin-minus nft-primary rounded-md shadow overflow-hidden mb-3">
                        <div className="nft-image position-relative overflow-hidden">
                          <LazyLoadImage
                            placeholder={<Skeleton variant="rounded" width={"100%"} height={220} />} 
                            src={s.image} style={{height: 220, width: "100%", objectFit: 'cover'}} className="img-fluid border" alt=""
                          />
                          {/* <img src={s.image} className="img-fluid" alt=""/> */}
                          <div className="bid-btn">
                            <Link to={`/product/${s._id}`} className="btn btn-pills"><i className="mdi mdi-gavel fs-5 align-middle me-1"></i> Bid</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="slider2">
              <div className="slide-track">
                {
                  loading ? 
                  [1,2,3,4,5].map((s, i) => (
                    <div key={i} className="slide mx-2">
                      <div>
                        < Skeleton variant="rounded" width={"100%"} height={220} />
                      </div>
                    </div>
                  )):
                  section2.map((s, i) => (
                    <div key={i} className="slide mx-2">
                        <div className="card bg-white nft-items nft-margin-minus nft-primary rounded-md shadow overflow-hidden mb-3">
                            <div className="nft-image position-relative overflow-hidden">
                              <LazyLoadImage
                                placeholder={<Skeleton variant="rounded" width={"100%"} height={220} />} 
                                src={s.image} style={{height: 220, width: "100%", objectFit: 'cover'}} className="img-fluid border" alt=""
                              />
                              <div className="bid-btn">
                                <a href="item-detail-one.html" className="btn btn-pills"><i className="mdi mdi-gavel fs-5 align-middle me-1"></i> Bid</a>
                              </div>
                            </div>
                        </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
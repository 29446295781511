import { useState } from "react"
import { serializeFormQuery } from "../utilities/helpers"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export function Search(){
  let [searchParams, setSearchParams] = useSearchParams()
  let [searchInput, setSearchInput] = useState(searchParams.get('search') || '')
  const { pathname } = useLocation()

  let navigate = useNavigate()
  const paths = ["/explore"]

  function search(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault()
    if(paths.includes(pathname)){
      setSearchParams({...serializeFormQuery(searchParams), search: searchInput})
    }
    else{
      navigate("/explore/marketplace?search=" + searchInput)   
    }
  }
  return(
    <div className="dropdown">
      <button type="button" className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="uil uil-search text-dark fs-5 align-middle"></i>
      </button>
      <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0" style={{width: 300}}>
        <div className="search-bar">
          <div id="itemSearch" className="menu-search mb-0">
            <form onSubmit={search} role="search" method="get" id="searchItemform" className="searchform">
              <input value={searchInput} onChange={e => setSearchInput(e.target.value)} type="search" className="form-control border rounded" name="s" id="searchItem" placeholder="Search..." />
              <input type="submit" id="searchItemsubmit" value="Search" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
interface IEmpty {
  title: string
  subtitle: JSX.Element
}
export function Empty({ title, subtitle }: IEmpty){
  return(
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-8 text-center">
        <img src="/assets/images/svg/office-desk.svg" className="img-fluid" alt="" />

        <div className="content">
          <h5 className="mb-4">{ title }</h5>
          <p className="text-muted">
            { subtitle }
          </p>
        </div>
      </div>
    </div>
  )
}
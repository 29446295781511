import { IconButton, LinearProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import dayjs from "dayjs";
import { Selectinput, Textarea, Textinput2 } from "../../components/textinput";
import { useFormik } from "formik";
import { Button } from "../../components";
import * as yup from 'yup'
import { categories } from "../../utilities/constant";
import { isEmpty } from "../../utilities/helpers";

export default function AllNFTs(){
  const [selected, setSelected] = useState({})
  const [page, setPage] = useState(1)
  const { makeRequest, data, loading, setData } = useRequest(api.allnfts)
  const [searchinput, setSearchinput] = useState("")

  async function search(e){
    e.preventDefault()
    setPage(1)
    await makeRequest({ search: searchinput, page }, "get")
  }

  useEffect(() => {
    makeRequest({ page }, "get")
  }, [page])
  return(
    <div>
      <div className="d-flex align-items-center mb-2 justify-content-between">
        <h3 className="mb-2">All NFTs</h3>
        <button data-bs-toggle="modal" data-bs-target="#mint-nft" className="btn btn-primary g-2 rounded-md">
          <span className="inline-block">Mint NFT</span>
        </button>
      </div>
      
      <NFTItem item={selected} setData={setData} />
      <MintNFT />
      <div>
        <form onSubmit={search} className="d-flex gap-2 mb-4 col-10 col-lg-4 align-items-center">
          <input 
            type="search"
            value={searchinput}
            onChange={e =>setSearchinput(e.target.value)}
            className="form-control form-control-sm ml-2" 
            placeholder="Search..." 
          />
        </form>
      </div>
      <TableContainer style={{borderRadius: 10}}>
        {loading && <LinearProgress />}
        <Table className="bg-light">
          <TableHead>
            <TableRow>
              <TableCell className="text-dark fw-bold">Name</TableCell>
              <TableCell className="text-dark fw-bold">Price (ETH)</TableCell>
              <TableCell className="text-dark fw-bold">Category</TableCell>
              <TableCell className="text-dark fw-bold">Listed</TableCell>
              <TableCell className="text-dark fw-bold">created at</TableCell>
              <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.data?.docs.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="text-dark">{row?.name}</TableCell>
              <TableCell className="text-dark">{row?.price}</TableCell>
              <TableCell className="text-dark">{row?.category}</TableCell>
              <TableCell className="text-dark">{ row?.is_listed ? "✅" : "❌" }</TableCell>
              <TableCell className="text-dark">{dayjs(row?.created_at).format("DD/MM/YY")}</TableCell>
              <TableCell className="text-dark">
                <IconButton 
                  onClick={() => setSelected(row)}
                  data-bs-toggle="modal" data-bs-target="#nft-item"
                  sx={{width: 30, height: 30}}
                >
                  <i style={{fontSize: 20}} className="uil uil-eye text-dark"></i>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={6}>
              <div style={{display: "flex", justifyContent: "end" }}>
                <Pagination
                  sx={{marginLeft: "auto"}} 
                  count={data?.data?.totalPages} 
                  page={page} onChange={(_,v) => setPage(v)} 
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
        
      </TableContainer>
    </div>
  )
}

function NFTItem({ item, setData }){
  const formik = useFormik({
    initialValues: item,
    async onSubmit(value){
      const { data, status } = await makeRequest(value, 'put')
      if(status === "success"){
        setData(s => ({
          ...s, data: {
            ...s.data,
            docs: s.data.docs.map(d => d._id === item?._id ? {...d, ...data} : d )
          }
        }))
      }
    }
  })

  const { makeRequest, loading } = useRequest(api.allnfts + "/" + formik?.values?._id)

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div className="modal fade" id="nft-item" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">{item?.name}</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <div className="border mx-auto rounded" style={{width: 140, padding: 5}}>
                  <img src={item?.image} className="rounded" style={{width: "100%", objectFit: "cover"}} />
                </div>
                <Textinput2
                  label="Name" name="name"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Price" name="price"
                  type="text" 
                  formik={formik}
                />
                <Selectinput 
                  label="Category" name="category"
                  data={Object.entries(categories).map(c => ({label: c[1], value: c[0]}))}
                  formik={formik}
                />
                <Selectinput
                  label="Is Listed" name="is_listed"
                  data={["list", "unlist"].map(c => ({label: c.toLocaleUpperCase(), value: String(c === "list") }))}
                  formik={formik}
                />

                {/* <Selectinput 
                  label="Status" name="status"
                  data={["pending", "approved", "declined"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik} style="col-lg-12"
                /> */}

                {/* <Textinput2
                  label="User id" name="user"
                  type="text" 
                  formik={formik}
                /> */}

                <Textarea
                  label="Description" 
                  name="description"
                  formik={formik}
                />

              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Update Art" />
          </div>
        </form>
      </div>
    </div>
  )
}

function MintNFT(){
  const { makeRequest, loading } = useRequest(api.allnfts)

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      user: "",
      description: "",
      price: "",
      status: "",
      image: ""
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required").min(2),
      category: yup.string().required("Category is required"),
      status: yup.string().required("Status is required"),
      user: yup.string().required("user id is required"),
      description: yup.string().required("Description is required"),
      price: yup.number().required("Price is required"),
      image: yup.string().required("image is required"),
    }),
    async onSubmit(values, helper) {
      const { status } = await makeRequest(values)
      if(status === "success") helper.resetForm()
    }
  })

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return(
    <div className="modal fade" id="mint-nft" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Mint NFT</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <input type="file" id="image" onChange={handleFileChange} name="image" hidden />
                <label htmlFor="image" className="border mx-auto rounded" style={{width: isEmpty(formik.values.image) ? 300 : 140, padding: 5}}>
                {
                  isEmpty(formik.values.image) ?
                  <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
                    <span>Supports JPG, PNG and MP4 videos. Max file size : 10MB.</span>
                  </div>:
                  <img src={formik.values.image} className="rounded" style={{width: "100%", objectFit: "cover"}} />
                }
                </label>
                {(formik.touched.image && formik?.errors.image) && (
                  <small className="text-danger text-start text-center mt-1 mb-3">{`${formik?.errors.image}`}</small>
                )}
                <Textinput2
                  label="Name" name="name"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Price" name="price"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="User id" name="user"
                  type="text" 
                  formik={formik}
                />
                <Selectinput 
                  label="Category" name="category"
                  data={Object.entries(categories).map(c => ({label: c[1], value: c[0]}))}
                  formik={formik}
                />

                {/* <Selectinput 
                  label="Status" name="status"
                  data={["pending", "approved", "declined"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik}
                /> */}

                <Textarea
                  label="Description" 
                  name="description"
                  formik={formik}
                />
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Mint NFT" />
          </div>
        </form>
      </div>
    </div>
  )
}
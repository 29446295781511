import { IconButton, LinearProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import dayjs from "dayjs";
import classNames from "classnames";
import { compareStrings, isEmpty } from "../../utilities/helpers";
import { Textinput2 } from "../../components/textinput";
import { useFormik } from "formik";
import { Button } from "../../components";

export default function Transactions(){
  const [selected, setSelected] = useState({})
  const [filter, setFilter] = useState("all")
  const [page, setPage] = useState(1)
  const { makeRequest, data, loading } = useRequest(api.transactions)

  useEffect(() => {
    makeRequest(compareStrings(filter, "all") ? {page } : { page, type: filter  }, "get")
  }, [filter, page])

  return(
    <div>
      <h3 className="mb-2">Transactions</h3>
      <ul className="nav nav-tabs border-bottom mb-4" id="sales" role="tablist">
        {
          ["all", "withdraw", "deposit", "mint", "commision"].map(p => (
            <li onClick={() => setFilter(p)} className="nav-item" role="presentation">
              <button className={classNames("nav-link text-capitalize", {"active": compareStrings(filter, p)})} type="button">{ p }</button>
            </li>
          ))
        }
      </ul>
      <TransactionItem item={selected} />
      <TableContainer style={{borderRadius: 10}}>
        { loading && <LinearProgress /> }
        <Table className="bg-light">
          <TableHead>
            <TableRow>
              <TableCell className="text-dark fw-bold">Narration</TableCell>
              <TableCell className="text-dark fw-bold">Amount (ETH)</TableCell>
              <TableCell className="text-dark fw-bold">Type</TableCell>
              <TableCell className="text-dark fw-bold">Status</TableCell>
              <TableCell className="text-dark fw-bold">Date</TableCell>
              <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.data?.docs.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="text-dark" sx={{ whiteSpace: 'nowrap', textWrap: "nowrap", overflowX: "hidden", textOverflow: "ellipsis" }}>{row?.description}</TableCell>
              <TableCell className="text-dark" align="center">{row?.amount}</TableCell>
              <TableCell className="text-dark" align="center">{row?.type}</TableCell>
              <TableCell className={classNames("text-capitalize text-dark")}>{ row?.status }</TableCell>
              <TableCell className="text-dark">{dayjs(row?.created_at).format("DD/MM/YY")}</TableCell>
              <TableCell className="text-dark">
                <IconButton
                  sx={{width: 30, height: 30}}
                  onClick={() => setSelected(row)}
                  data-bs-toggle="modal" data-bs-target="#txn-item"
                >
                  <i style={{fontSize: 20}} className="uil uil-eye text-dark"></i>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={6}>
              <div style={{display: "flex", justifyContent: "end" }}>
                <Pagination
                  sx={{marginLeft: "auto"}} 
                  count={data?.data?.totalPages} 
                  page={page} onChange={(_,v) => setPage(v)} 
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
        
      </TableContainer>
    </div>
  )
}

function TransactionItem({ item }){
  console.log(item)
  const formik = useFormik({
    initialValues: item,
    async onSubmit(value){
  
    }
  })

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div className="modal fade" id="txn-item" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Transactions details</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <Textinput2
                  label="Reference" name="_id"
                  type="text" disabled
                  formik={formik}
                />
                <Textinput2
                  label="Amount (ETH)" name="amount"
                  type="text" disabled
                  formik={formik}
                />
                <Textinput2
                  label="Type" 
                  name="type"disabled
                  formik={formik}
                />
                <Textinput2
                  label="Status" 
                  name="status"disabled
                  formik={formik}
                />

                <Textinput2
                  label="Narration" 
                  name="description"disabled
                  formik={formik}
                />
                {
                  !isEmpty(formik.values?.recipient) &&
                  <Textinput2
                  label="Recipient" 
                    name="recipient"
                    disabled
                    formik={formik}
                  />
                }
                {
                  !isEmpty(formik.values?.sender) &&
                  <Textinput2
                    label="Sender" 
                    name="sender"
                    disabled
                    formik={formik}
                  />
                }
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button type="submit" title="Close" />
          </div>
        </div>
      </div>
    </div>
  )
}
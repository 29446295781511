import { Link } from "react-router-dom";
import { Footer, Header } from "../components";

export function Terms(){
  return(
    <div>
      <Header />
      <section className="bg-half-170 d-table w-100" style={{background: "url('/assets/images/bg/02.jpg') center"}}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">Terms Of Service</h5>
              </div>
            </div>
          </div>

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item"><Link to="/">Artverse</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Terms of service</li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="card shadow border-0 rounded">
                <div className="card-body">
                    <h5 className="card-title">Artverse Marketplace :</h5>
                    <p className="text-muted mt-4">
                      Welcome to Artverse, an online marketplace for trading Non-Fungible Tokens (NFTs). These Terms and Conditions outline the rules and guidelines for using our marketplace. By accessing and using Artverse, you agree to be bound by these terms. Please read them carefully before participating in any transactions on our marketplace..
                    </p>

                    <h5 className="card-title mt-5">1.General Terms</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">1.1 Eligibility:</b> In order to participate in the Artverse marketplace, you must meet the eligibility requirements. This means that you must be at least 18 years old or have the legal capacity to enter into contracts as per the laws of your jurisdiction. By accessing and using Artverse platform, you confirm that you meet these eligibility criteria.
                        </p>
                        <p>
                          <b className="text-danger">1.2 Account Creation:</b>
                           To engage in NFT trading on Artverse, you are required to create a user account on Artverse. During the account creation process, you will be asked to provide certain information, including personal details and contact information. It is your responsibility to ensure that the information you provide is accurate, up-to-date, and complete. You are also responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must promptly notify Artverse if you become aware of any unauthorized access or use of your account.
                        </p>
                        <p>
                          <b className="text-danger">1.3 Fees and Payments:</b>
                          When creating and listing new art or items on Artverse, a minting fee of 0.1 ETH will be charged per art or item. Artverse charges 15% commission on every NFT sales payable in ETH. It is your responsibility as a seller to review and understand the applicable fees associated with each transaction. Payments for NFT purchases will be processed securely through our designated payment processor.                        
                        </p>
                        <p>
                          <b className="text-danger">1.4 Compliance with Laws:</b>
                          When using the Artverse platform, you agree to comply with all applicable laws, rules, and regulations governing the purchase, sale, and trading of NFTs. It is your responsibility to understand and adhere to the legal requirements specific to your jurisdiction. By accessing and using Artverse, you confirm that your activities on the platform are lawful in your jurisdiction and that you will not engage in any illegal or prohibited activities.                        
                        </p>
                        <p>
                          <b className="text-danger">1.5 Platform Usage:</b>
                          Artverse serves as an online marketplace that facilitates the buying, selling, and trading of NFTs. It is important to note that Artverse does not own or endorse any of the NFTs listed on the platform. We act as an intermediary, providing the platform for users to connect and transact. As such, any transactions, agreements, or interactions between users are solely the responsibility of the participating users. Artverse does not guarantee the accuracy, quality, legality, or authenticity of the NFTs listed on the platform. It is your responsibility to conduct due diligence and assess the authenticity, ownership rights, and accuracy of the NFTs before engaging in any transactions.                        
                        </p>
                    </div>

                    <h5 className="card-title mt-5">2. NFT Listings and Transactions</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">2.1 Ownership and Authenticity:</b> As a seller on Artverse, it is imperative that you have the legal rights to sell the NFTs you list on the platform. By listing an NFT for sale, you represent and warrant that you are the rightful owner of the NFT and have the authority to transfer ownership to the buyer. Artverse may require you to undergo an insurance verification process to confirm your ownership rights and to provide a level of assurance to potential buyers. It is your responsibility to ensure that the NFTs you list are accurately described and that the images and information provided reflect the actual item for sale.
                        </p>
                        <p>
                          <b className="text-danger">2.2 Prohibited Content:</b>
                          Artverse strictly prohibits the listing or trading of NFTs that are illegal, fraudulent, offensive, infringe on intellectual property rights, or violate these Terms and Conditions. You may not list NFTs that contain explicit or adult content, promote hate speech, or engage in any form of illegal activity. Artverse reserves the right to remove any content that violates these guidelines and may take appropriate action, including suspending or terminating the accounts of users who repeatedly violate these rules.
                        </p>
                        <p>
                          <b className="text-danger">2.3 Disputes and Refunds:</b>
                          In the event of any disputes between buyers and sellers, Artverse encourages users to resolve such issues amicably. Artverse does not act as an arbiter in these disputes and is not responsible for mediating or resolving them. The resolution of disputes, including refunds or returns of NFTs, is at the discretion of the seller unless otherwise required by applicable laws or regulations. It is recommended that buyers and sellers communicate openly and honestly to address any concerns or discrepancies that may arise during the transaction process.                      
                        </p>
                    </div>

                    <h5 className="card-title mt-5">3. Intellectual Property</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">3.1 Copyright:</b> Artverse expects all users to respect the intellectual property rights of others. You may not use, reproduce, distribute, or modify any copyrighted material without proper authorization from the rights holder. When listing an NFT for sale on Artverse, you must ensure that you have the necessary rights, licenses, or permissions to include any copyrighted material within the NFT. By listing an NFT on the platform, you affirm that you are not infringing upon any third party intellectual property rights. If your account should get flagged for a copyright infringement case, you will be required to maintain a minimum balance of 1 ETH which will serve as an insurance cover for the verification/resolution process. This amount will be fully refunded upon resolution of the copyright infringement case.
                        </p>
                        <p>
                          <b className="text-danger">3.2 License: </b>
                          By listing an NFT for sale on Artverse, you grant Artverse a non-exclusive, worldwide, royalty-free license to display, reproduce, and promote the NFT to facilitate transactions on the platform. This license allows Artverse to showcase the NFT in listings, search results, promotional materials, and other marketing efforts related to the platform. The license granted to Artverse is limited to the extent necessary to provide the services and functionalities of the platform. Please note that this license does not transfer ownership of the NFT to Artverse or any other users. Ownership rights remain with the respective NFT owners, and Artverse does not claim any ownership or control over the NFTs listed on the platform. It is your responsibility as a user to ensure that your actions on Artverse comply with applicable intellectual property laws and respect the rights of others. Artverse reserves the right to remove any NFT listings or take appropriate action in response to notices of alleged copyright infringement or other intellectual property violations. If you believe that your copyrighted work has been infringed upon on the Artverse platform, please follow the procedures outlined in our Copyright Policy to report the infringement.
                        </p>
                    </div>

                    <h5 className="card-title mt-5">4. Termination and Suspension</h5>
                    <div className="text-muted mt-4">
                      <p>
                        <b className="text-danger">4.1 Termination:</b> 
                        Artverse reserves the right to suspend or terminate your account or access to the platform at any time and for any reason. This includes, but is not limited to, violations of these Terms and Conditions suspected fraudulent activity, or any other conduct that Artverse deems inappropriate or harmful to the platform or its users. In the event of termination, you will no longer have access to your account and any ongoing transactions or listings may be canceled.                      </p>
                      <p>
                        <b className="text-danger">4.2 Account Deletion: </b>
                        If you wish to delete your account on Artverse, please contact our support team or follow the account deletion procedures specified on the platform. Deleting your account will permanently remove your personal information and any associated data from the platform. Please note that once your account is deleted, it cannot be restored, and any ongoing transactions or listings will be canceled. Artverse reserves the right to retain certain information related to your account, as required by law or for legitimate business purposes, even after account deletion. Such retained information will be handled in accordance with Artverse's Privacy Policy.                      </p>
                    </div>

                    <h5 className="card-title mt-5">5. Limitation of Liability</h5>
                    <div className="text-muted mt-4">
                      <p>
                        5.1 Artverse and its affiliates will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the platform. This includes but is not limited to, loss of profits, data, or reputation, even if Artverse has been advised of the possibility of such damages.                      
                      </p>
                      <p>
                        5.2 Artverse strives to provide a secure and reliable platform for NFT trading, but we do not guarantee the continuous availability, accuracy, or reliability of the platform or its services. While we take reasonable measures to maintain the security of user accounts and transactions, Artverse cannot guarantee that unauthorized third parties will not gain access to user information or interfere with the platform's operations. Therefore, you agree to use Artverse at your own risk.
                      </p>
                    </div>

                    <h5 className="card-title mt-5">6. Amendments</h5>
                    <div className="text-muted mt-4">
                      <p>
                        6.1  Artverse reserves the right to modify these Terms and Conditions at any time without prior notice. Any changes or updates to these terms will be effective immediately upon posting on Artverse website or through other means of communication provided by Artverse. It is your responsibility to review these Terms and Conditions periodically to stay informed of any changes.                      </p>
                      <p>
                        6.2 By continuing to use Artverse after any modifications to the Terms and Conditions have been made, you acknowledge and agree to be bound by the revised terms. If you do not agree with the modified terms, you must stop using Artverse.
                      </p>
                      <p>
                        6.3 Artverse may also introduce additional terms, policies, or guidelines that govern specific features, services, or offerings provided on the platform. Such additional terms will be presented to you and will be considered as part of these Terms and Conditions.
                      </p>
                      <p>
                        6.4 In the event of any conflict or inconsistency between these Terms and Conditions and any additional terms or policies, the additional terms or policies will prevail to the extent of the inconsistency. Artverse encourages you to regularly review the Terms and Conditions to ensure that you are aware of any updates or changes that may affect your use of the platform.                      </p>
                    </div>

                    {/* <div className="mt-4">
                        <a href="javascript:void(0)" className="btn btn-primary rounded-md mt-2 me-2">Accept</a>
                        <a href="javascript:void(0)" className="btn btn-outline-primary rounded-md mt-2">Decline</a>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
import { useState } from "react";
import { toast } from "react-toastify";
import { string } from "yup";

export default function useCopy(): [boolean, (t: string, o: any) => any]{
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string, { success, error }: {success: string, error?: string}) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      toast(success, {type: "success"})
    }, (err) => {
      setCopied(false);
      toast(success, {type: "error"})
      console.error('Could not copy text: ', err);
    });
  };

  return [copied, copyToClipboard]
}
export const maskEmail = (email: string) => {
  const [username, domain] = email.split('@');
  const mask = username.slice(0, 4) + '*'.repeat(Math.floor(username.length / 2)) + username.charAt(username.length - 1);
  return mask + '@' +  domain
}

export const maskAddress = (address: string) => {
  return isEmpty(address) ? address : address?.slice(0, 5) + '*'.repeat(4) + address.slice(-5);
}

export const isEmpty = (mixedVar: any) => {
  let undef;
  let key;
  let i;
  let len;
  const emptyValues = [undef, null, false, 0, '', '0', 'null', 'undefined'];

  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i] || typeof mixedVar == 'undefined') {
      return true;
    }
  }

  if (typeof mixedVar === 'object' && !(mixedVar instanceof Date)) {
      for (key in mixedVar) {
        if (mixedVar.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
  }
  return false;
};

export const paramsToObject = (params: string) => {
  params = params.replace("?", "")
  return params.split("&").reduce((acc, v) => {
    const [key, value] = v.split("=")
    acc[key] = value;
    return acc
  }, {})
}

export const compareStrings = (str1: string, str2: string) => {
  return str1?.toLowerCase().trim() === str2?.toLowerCase().trim();
}

export function formatToInternational(phone: string, prefix: string) {
  if (phone.startsWith("+")) return phone;
  if (phone.startsWith('0')) return prefix + phone.slice(1);
  if (!phone.startsWith(prefix)) return prefix + phone;
  if (!phone.startsWith("+")) return '+' + phone;
  return phone;
}

export const handleFileChange = (cb: (result: string | ArrayBuffer) => any) => (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => cb(reader.result);
    reader.readAsDataURL(file);
  }
};

export function serializeFormQuery(search: URLSearchParams){
  let params: any = {}
  for (let [key, value] of search.entries()){
    if(key === "price_range"){
      params[key] = JSON.parse(value)
    }
    else{
      params[key] = value
    }
  }
  console.log(params)
  return params
}
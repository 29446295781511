import { Link } from "react-router-dom"
import { Button } from "../../components"
import numeral from 'numeral'
import { useStore } from "../../hooks/use-store"
import api from "../../utilities/api"
import { useRequest } from "../../hooks/use-request"
import { useFormik } from "formik"
import QRCode from "react-qr-code";
import * as yup from 'yup'
import useCopy from "../../hooks/use-copy"
import { Textinput2 } from "../../components/textinput"
import { useState } from "react"

export default function Account(){
  const [type, setType] = useState("main")
  const { profile } = useStore()
  return(
    <div>
      <h3 className="mb-2">Account summary</h3>
      <div className="row">
        <div className="col-lg-6">
          <div className="rounded shadow-sm p-3 bg-light">
            <div>Account balance</div>
            <h3>{ numeral(profile?.balance).format("0.00") } ETH</h3>
            <div className="my-5"></div>
            <Button title="deposit" icon={<i className="uil uil-arrow-down-right"></i>} data-bs-toggle="modal" data-bs-target="#deposit" />
            <a onClick={() => setType("main")} className="btn btn-outline-primary me-2 rounded-pill" data-bs-toggle="modal" data-bs-target="#withdraw">
              <i className="uil uil-arrow-up-left"></i>
              Withdraw
            </a>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="rounded shadow-sm p-3 bg-light">
            <div>Sales balance</div>
            <h3>{ numeral(profile?.sales_balance).format("0.00") } ETH</h3>
            <div className="my-5"></div>
            {/* <Button title="deposit" icon={<i className="uil uil-arrow-down-right"></i>} data-bs-toggle="modal" data-bs-target="#deposit" /> */}
            <a onClick={() => setType("sales")}  className="btn btn-outline-primary me-2 rounded-pill" data-bs-toggle="modal" data-bs-target="#withdraw">
              <i className="uil uil-arrow-up-left"></i>
              Withdraw
            </a>
          </div>
        </div>
      </div>
      
      
      <Withdraw type={type} />
      <Deposit />
      <div className="bg-black rounded-md p-md-4 p-3 mt-5">
        <div className="row d-flex flex-row align-items-center">
          <div className="col-lg-3 col-md-5">
            <img src="/assets/images/cta.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-8 col-md-6">
            <div className="section-title text-center text-md-start ms-xl-5 ms-md-4">
              <h4 className="display-6 fw-bold text-white title-dark mb-0">Enhance Your <span className="text-gradient-primary fw-bold">Collection as You Mint</span> <br /></h4>
          
              <div className="mt-4">
                <Link to="/dashboard/mint" className="btn btn-link primary text-white title-dark">Mint NFT <i className="uil uil-arrow-right"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Withdraw({ type }){
  const { profile } = useStore()
  const { makeRequest, loading } = useRequest(api.withdraw)

  const formik = useFormik({
    initialValues: {
      amount: "",
      address: ""
    },
    validationSchema: yup.object({
      amount: yup.number().required("Enter an amount to withdraw"),
      address: yup.string().required("Enter your ETH wallet address"),
    }),
    async onSubmit(values){
      await makeRequest({ ...values, type })
    } 
  })
  return(
    <div className="modal fade" id="withdraw" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md">
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Withdraw</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4">
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-4">
                    <label className="form-label fw-bold">Network</label>
                    <input name="name" id="name" type="text" value="Ethereum (ERC20)" readOnly className="form-control" placeholder="00.00 ETH" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <label htmlFor="address" className="form-label fw-bold">Wallet address <span className="text-danger">*</span></label>
                    <input name="address" { ...formik.getFieldProps("address") } id="address" type="text" className="form-control" placeholder="Enter/Paste wallet address here." />
                    {(formik.touched.address && formik?.errors.address) && (
                      <small className="text-danger text-start">{`${formik?.errors.address}`}</small>
                    )}
                  </div> 
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="address" className="form-label fw-bold">Amount(ETH) <span className="text-danger">*</span></label>
                      <small className="text-muted"><span className="text-dark">Avl bal:</span> { type === "sales" ? profile?.sales_balance : profile?.balance } ETH</small>
                    </div>
                    <input name="amount" { ...formik.getFieldProps("amount") } id="amount" type="number" className="form-control" placeholder="Enter Amount " />
                    <div></div>
                    {(formik.touched.amount && formik?.errors.amount) && (
                      <small className="text-danger text-start">{`${formik?.errors.amount}`}</small>
                    )}
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button 
              title="Withdraw" 
              icon={<i className="mdi mdi-gavel fs-5 me-2"></i> } 
              loading={loading}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

function Deposit(){
  const { makeRequest, loading } = useRequest(api.deposit)
  const address = "0x5a709E26Ca126ab484041d7580fC9990F6E47E1b"
  const [copied, handleCopy] = useCopy()

  const formik = useFormik({
    initialValues: { hash: "", amount: "" },
    validationSchema: yup.object({
      hash: yup.string().required("Hash is required").min(5),
      amount: yup.number().required("Amount is required")
    }),
    async onSubmit(values, helper) {
      const { status } = await makeRequest(values)
      if(status === "success") helper.resetForm()
    }
  })
  return(
    <div className="modal fade" id="deposit" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Deposit</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <div className="border mx-auto rounded" style={{width: 200}}>
                  <QRCode 
                    size={200} 
                    style={{width: "100%", borderWidth: 1}} 
                    value={address} 
                  />
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <label className="form-label fw-bold">Network</label>
                    <input id="name" value="Ethereum (ERC20)" readOnly className="form-control" placeholder="00.00 ETH" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="address" className="form-label fw-bold">Wallet address</label>
                      <span 
                        onClick={() => handleCopy(address, {success: "address copied successfully"}) } 
                        style={{fontSize: 16, cursor: "pointer"}} className="text-primary fw-bold"
                      >COPY</span>
                    </div>
                    <input id="address" className="form-control" value={address} readOnly />
                  </div> 
                </div>
                <Textinput2 name="hash" placeholder="0x4b05449****d3f6bb11b" formik={formik} label="Transaction hash" />
                <Textinput2 name="amount" formik={formik} placeholder="0.03" label="Amount" />
              </div>
            </div>

            <div className="pt-3 border-top">
              {
                [
                  {title: "Expected arrival", subtitle: '12 network confirmations'},
                  {title: "Minimum deposit", subtitle: '0.00000001 ETH'},
                  {title: "Expected unlock", subtitle: '12 network confirmations'}
                ].map(({title, subtitle}, i) => (
                  <div key={i} className="d-flex justify-content-between">
                    <p className="fw-semibold text-muted small"> {title}:</p>
                    <p className="fw-bold"> {subtitle} </p>
                  </div>
                ))
              }

              <h5>Notes:</h5>
              <ul>
                <li>Send only ETH to this deposit address.</li>
                <li>Ensure the network is Ethereum (ERC20).</li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <Button 
              type="submit"
              loading={loading} 
              title="I have completed the deposit"
            />
          </div>
        </form>
      </div>
    </div>
  )
}
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Skeleton } from "@mui/material"
import { useStore } from "../hooks/use-store"
import numeral from "numeral"
import { isEmpty } from "../utilities/helpers"

export interface INFT {
  image: string
  name: string
  price: string
  _id: string
  user: any,
  style?: React.CSSProperties
}

export function Nft({ image, name, user, price, _id, style }: INFT){
  const { rate } = useStore()
  return(
    <div style={style} className="col picture-item">
      <Link to={`/product/${_id}`} className="card bg-white nft-items nft-primary rounded-md shadow overflow-hidden mb-1">
        <div className="nft-image position-relative overflow-hidden">
          <LazyLoadImage 
            placeholder={<Skeleton variant="rounded" sx={{ width: "100%", height: 200, minHeight: 200 }} />} 
            src={image} style={{ width: "100%", maxHeight: 200 , height: '75%', objectFit: 'cover'}} className="img-fluid" alt=""
          />
        </div>

        <div style={{padding: 22}} className="card-body content position-relative">
          {
            !isEmpty(user?.avatar) &&
            <div className="img-group">
              <a className="user-avatar">
                <img src={user?.avatar} alt="user" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-md rounded-circle"/>
              </a>
            </div>
          }

          <div className="">
            <Link to={`/product/${_id}`} className="title text-dark h6">{name}</Link>
            <div />
            {/* <small style={{fontWeight: '500'}} className="title">johndoe{ user?.username }</small> */}

            <div className="d-flex flex-wrap align-items-center mt-2">
              <small className="rate fw-bold">{ price } ETH</small>
              {
                !isEmpty(rate) &&
                <small style={{fontSize: 12}} className="text-dark mz fw-bold">(${ numeral(price).multiply(Number(rate)).format("0,0.00")})</small>
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
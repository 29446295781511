import { 
  IconButton, LinearProgress, 
  Pagination, 
  Table, TableBody, TableCell, 
  TableContainer, TableHead, TablePagination,
  TableRow 
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import dayjs from "dayjs";
import { maskAddress } from "../../utilities/helpers";

export default function AllSales(){
  const [page, setPage] = useState(1)
  const { makeRequest, data, loading } = useRequest(api.allorders)
  const [searchinput, setSearchinput] = useState("")
  
  async function search(e){
    e.preventDefault()
    setPage(1)
    await makeRequest({ search: searchinput, page }, "get")
  }

  useEffect(() => {
    makeRequest({ page }, "get")
  }, [page])
  return(
    <div>
      <h3 className="mb-2">Sales</h3>
      <div>
        <form onSubmit={search} className="d-flex gap-2 mb-4 col-10 col-lg-4 align-items-center">
          <input 
            type="search"
            value={searchinput}
            onChange={e =>setSearchinput(e.target.value)}
            className="form-control form-control-sm ml-2" 
            placeholder="Search by ID..." 
          />
        </form>
      </div>
      <TableContainer style={{borderRadius: 10}}>
        {loading && <LinearProgress />}
        <Table className="bg-light">
          <TableHead>
            <TableRow>
              <TableCell className="text-dark fw-bold">Reference</TableCell>
              <TableCell className="text-dark fw-bold">Name</TableCell>
              <TableCell className="text-dark fw-bold">Amount (ETH)</TableCell>
              <TableCell className="text-dark fw-bold">Buyer</TableCell>
              <TableCell className="text-dark fw-bold">Seller</TableCell>
              <TableCell className="text-dark fw-bold">Created At</TableCell>
              <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.data?.docs.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="text-dark" component="th" scope="row">
                {row._id}
              </TableCell>
              <TableCell className="text-dark">{row?.name}</TableCell>
              <TableCell className="text-dark">{ row?.amount }</TableCell>
              <TableCell className="text-dark">{maskAddress(row?.buyer)}</TableCell>
              <TableCell className="text-dark">{maskAddress(row?.seller)}</TableCell>
              <TableCell className="text-dark">{dayjs(row?.created_at).format("DD/MM/YY")}</TableCell>
              {/* <TableCell className="text-dark">
                  <IconButton
                    onClick={() => setSelected(row)}
                    sx={{width: 30, height: 30}}
                    data-bs-toggle="modal" data-bs-target="#user-item"
                  >
                    <i style={{fontSize: 20}} className="uil uil-eye"></i>
                  </IconButton>
                </TableCell> */}
            </TableRow>
          ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <div style={{display: "flex", justifyContent: "end" }}>
                <Pagination
                  sx={{marginLeft: "auto"}} 
                  count={data?.data?.totalPages} 
                  page={page} onChange={(_,v) => setPage(v)} 
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
        
      </TableContainer>
    </div>
  )
}
import { compareStrings } from "../utilities/helpers"
import { useStore } from "./use-store"

export default function useSidebar(){
  const { profile } = useStore()
  const is_admin = compareStrings(profile?.role, 'admin')

  return [
    {title: "All Users", icon: <i className="uil uil-users-alt"></i>, to: "/dashboard/users", allow: is_admin},
    {title: "All NFTs", icon: <i className="uil uil-folder-open"></i>, to: "/dashboard/nfts", allow: is_admin},
    {title: "All Sales", icon: <i className="uil uil-shopping-cart-alt"></i>, to: "/dashboard/allsales", allow: is_admin},
    {title: "All Transactions", icon: <i className="uil uil-exchange"></i>, to: "/dashboard/alltransactions", allow: is_admin},
    {title: "Account summary", icon: <i className="uil uil-wallet"></i>, to: "/dashboard/account", allow: !is_admin},
    {title: "Mint NFT", icon: <i className="uil uil-folder-plus"></i>, to: "/dashboard/mint", allow: true},
    {title: "Collection", icon: <i className="uil uil-folder-open"></i>, to: "/dashboard/collection", allow: !is_admin},
    {title: "Transactions", icon: <i className="uil uil-exchange"></i>, to: "/dashboard/transactions", allow: !is_admin},
    {title: "Sales", icon: <i className="uil uil-shopping-cart-alt"></i>, to: "/dashboard/sales", allow: !is_admin},
    {title: "Market place", icon: <i className="uil uil-shopping-bag"></i>, to: "/explore/marketplace", allow: true},
    {title: "Settings", icon: <i className="uil uil-setting"></i>, to: "/dashboard/settings", allow: true},
  ].filter(s => s.allow)
}
import { useEffect, useState } from "react";
import { Button, Empty, Footer, Header, NFTSkeleton, Nav, Nft } from "../components";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { category_images } from "../utilities/constant";
import { Pagination } from "@mui/material";
import { useRequest } from "../hooks/use-request";
import api from "../utilities/api";
import { compareStrings } from "../utilities/helpers";

export function Explore(){
  let [searchParams, setSearchParams] = useSearchParams()
  const [seemore, setSeeMore] = useState(true)
  const [page, setPage] = useState(1)
  const { category } = useParams()
  const navigate = useNavigate()
  const { makeRequest, loading, data } = useRequest(api.nfts)

  const { cover, main, title, info, props, items } = category in category_images ? category_images[category] : category_images["marketplace"]

  useEffect(() => {
    const filter = category in category_images && category !== "marketplace" ? { category } : {  }
    makeRequest({
      ...filter,
      search: searchParams.get('search'), 
      page
    }, "get")
  }, [category, page, searchParams])
  return(
    <div className="bg-light">
      <Header />

      <div className="position-relative" style={{ backgroundImage: `url(${cover || "/assets/images/category/art-cover.jpg"})`, width: "100%", height: 300 }} >
        <div className="position-absolute top-0 end-0 start-0" style={{ width: "100%", paddingTop: 80, height: '100%', background: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))'}}>
          <Nav active={category} style="light" />
        </div>
        <div className="position-absolute d-flex justify-content-center align-items-center bottom-0 border-5 bg-light" style={{ transform: 'translate(0, 50%)', borderRadius: 9, overflow: "hidden", borderWidth: 10, left: "4%", width: 120, height: 120 }}>
          <img src={main || "/assets/images/category/art.png"} style={{ width: "90%", height: '90%', borderRadius: 9  }} />
        </div>
      </div>

      <section className="section">
        <div className="container">
          <h2 className="title">{ title }</h2>
          <div className="d-flex g-4 fs-6">
            <p style={{marginRight: 20}} className="mr-3 inline-block">Items <strong>{ items }</strong></p>
            <p>Chain <strong>Ethereum</strong></p>
          </div>
          <div className="fs-6 col-md-10 col-12">
            { info.slice(0, seemore ? 150 : category_images[category]?.info.length).concat(seemore  && "...") }
            <div></div>
            <strong style={{cursor: "pointer"}} onClick={() => setSeeMore(p => !p)}>See {seemore ? "more" : "less"}</strong>
          </div>
          <div className="d-flex flex-wrap gx-5 mt-3">
            {
              props.map((p, i) => (
                <div style={{marginRight: 20}} className="d-flex mb-1  flex-column align-items-start">
                  <strong className="text-center">{p.value}</strong>
                  <span className="text-capitalize text-center">{p.title}</span>
                </div>
              ))
            }
          </div>
        </div> 
      </section>

      <section>
        <div className="container mb-5 pb-5">
          {
            loading ?
            <div className="responsive-grid" id="grid">
              {[1,2,3,4].map(i => <NFTSkeleton key={i} />)}
            </div> :
            data?.data?.totalDocs === 0 ?
            <Empty
              title="Empty collection"
              subtitle={
              <>
                More collections comming soon.
                <div className="mt-2">
                  <Button title="Mint NFT" onClick={() => navigate("/dashboard/collection")} />
                </div>
              </>} 
            />: 
            <div className="responsive-grid">
              { data?.data?.docs.map((n, i) => <Nft {...n} key={i} />) }
            </div>
          }
          <div className="d-flex mt-4 justify-content-end">
            <Pagination 
              count={data?.data?.totalPages}
              page={page || -1} variant="outlined"
              onChange={(_, value) => setPage(value)}
              color="primary" shape="rounded"
              className="pagination lc-pagination gap-2"
            />
          </div>
        </div>
      </section>

      <section className="section pt-0 pt-sm-5 mt-0 mt-sm-5">
        <div className="container">
          <div className="bg-black rounded-md p-md-5 p-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="app-subscribe text-center text-md-start">
                    <img src="/assets/images/cta.png" className="img-fluid" height="120" alt="" />
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="section-title text-center text-md-start ms-xl-5 ms-md-4">
                  <h4 className="display-6 fw-bold text-white title-dark mb-0">Enhance Your <span className="text-gradient-primary fw-bold">Collection as You Mint</span> <br /></h4>
                
                    <div className="mt-4">
                      <Link to="/dashboard/mint" className="btn btn-link primary text-white title-dark">Mint NFT <i className="uil uil-arrow-right"></i></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

// row gap-3 row-cols-xl-4 row-cols-lg-3 row-cols-sm-2
export const categories = {
  "art": "Art",
  "gaming": 'Gaming',
  "membership": "Membership",
  "pfps": 'PFPs',
  "photography": "Photography",
  "other": 'Other',
}

export const category_images = {
  'art': {
    title: "Arts",
    cover: "/assets/images/category/art-cover.jpg",
    main: "/assets/images/category/art.png",
    items: 2116,
    info: "Immerse yourself in the world of digital art with our NFT platform's dedicated art category. Discover and collect one-of-a-kind digital artworks created by talented artists from around the globe. From stunning paintings and sculptures to mesmerizing animations and immersive installations, experience the limitless possibilities of digital creativity.",
    props: [
      {title: "total volume", value: '6274 ETH'},
      {title: "listed", value: '76%'},
      {title: "owners", value: '634'},
      {title: "unique owners", value: '19%'}
    ]
  },
  'gaming': {
    title: "Gaming",
    items: 406,
    cover: "/assets/images/category/gaming-cover",
    main: "/assets/images/category/gaming.mp4",
    info: "Immerse yourself in the exciting world of NFT gaming with our dedicated gaming category. Collect rare in-game items, virtual assets, and unique characters that can be used across various gaming platforms and decentralized applications. Experience the thrill of owning and trading valuable digital items that enhance your gaming experience, while also having the potential to appreciate in value over time.",
    props: [
      {title: "total volume", value: '1740 ETH'},
      {title: "listed", value: '69%'},
      {title: "owners", value: '138'},
      {title: "unique owners", value: '12%'}
    ]
  },
  'membership': {
    title: "Memberships",
    items: 301,
    cover: "/assets/images/category/memberships-cover.png",
    main: "/assets/images/category/memberships.webp",
    info: "Unlock premium features, exclusive content, and exciting perks by becoming a member of our NFT platform. Collect membership NFTs that grant you access to special communities, events, and collaborations. Enjoy early access to new releases, discounts on purchases, and the ability to connect with like-minded collectors and creators in an exclusive environment.",
    props: [
      {title: "total volume", value: '1131 ETH'},
      {title: "listed", value: '61%'},
      {title: "owners", value: '90'},
      {title: "unique owners", value: '13%'}
    ]
  },
  'pfps': {
    title: "PFPs",
    items: 230,
    cover: "/assets/images/category/pfps-cover.png",
    main: "/assets/images/category/pfps.gif",
    info: "Customize your online presence with exclusive and eye-catching Profile Picture (PFP) NFTs. Express your personality and individuality through a diverse selection of unique avatar designs, digital portraits, and animated characters. Stand out from the crowd and elevate your online identity with limited-edition PFPs on our platform.",
    props: [
      {title: "total volume", value: '870 ETH'},
      {title: "listed", value: '73%'},
      {title: "owners", value: '69'},
      {title: "unique owners", value: '14%'}
    ]
  },
  'photography': {
    title: "Photography",
    items: 322,
    cover: "/assets/images/category/photography-cover.png",
    main: "/assets/images/category/photography.webp",
    info: "Discover the power of visual storytelling through the captivating medium of digital photography. Browse our photography category to explore breathtaking landscapes, intimate portraits, striking still life compositions, and thought-provoking documentary series. Each photograph is meticulously captured and curated by talented photographers, ensuring a collection of truly unique and immersive visual experiences.",
    props: [
      {title: "total volume", value: '1218 ETH'},
      {title: "listed", value: '89%'},
      {title: "owners", value: '97'},
      {title: "unique owners", value: '10%'}
    ]
  },
  'marketplace': {
    title: "Marketplace",
    items: 4375,
    cover: "/assets/images/category/photography-cover.png",
    main: "/assets/images/category/photography.webp",
    info: "Explore a vibrant digital marketplace where creators and collectors come together to buy, sell, and trade unique NFTs. Discover a wide range of digital assets, including art, collectibles, music, videos, and more, all authenticated on the blockchain for proven ownership and rarity.",
    props: [
      {title: "total volume", value: '31847.7656 ETH'},
      {title: "listed", value: '76%'},
      {title: "owners", value: '13266'},
      {title: "unique owners", value: '42%'}
    ]
  },
  'other': {
    title: "Others",
    items: 1000,
    cover: "/assets/images/category/other-cover.jpg",
    main: "/assets/images/category/other",
    info: "Embark on a journey through uncharted digital landscapes with our latest category. Explore a diverse collection of unconventional creations, each pushing the boundaries of creativity. From avant-garde digital expressions to mind-bending conceptual pieces, this category is a playground for those who seek the extraordinary. Immerse yourself in a world where the unconventional reigns supreme, and where every creation tells a story that defies the ordinary.",
    props: [
      {title: "total volume", value: '7004 ETH'},
      {title: "listed", value: '79%'},
      {title: "owners", value: '816'},
      {title: "unique owners", value: '17%'}
    ]
  },
}
import { useFormik } from "formik"
import * as yup from 'yup'
import { useRequest } from "../../hooks/use-request"
import api from "../../utilities/api"
import { categories } from "../../utilities/constant"
import { isEmpty } from "../../utilities/helpers"

const initialValues = {
  name: "",
  category: "",
  description: "",
  price: "",
  image: ""
}

export default function Upload(){
  const { makeRequest, loading } = useRequest(api.mint)

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().required("Name is required").min(2),
      category: yup.string().required("Category is required"),
      description: yup.string().required("Description is required"),
      price: yup.number().required("Price is required"),
      image: yup.string().required("image is required"),
    }),
    async onSubmit(values, helper) {
      const { status } = await makeRequest(values)
      if(status === "success") helper.resetForm()
    }
  })

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return(
    <form onSubmit={formik.handleSubmit} className="row">
      <h3 className="mb-2">Mint NFT</h3>
      <div className="col-lg-5">
        <div className="d-grid">
          <p className="fw-semibold mb-4">Upload your NFT here, Please click "Upload Image" Button.</p>
          <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
            {
              isEmpty(formik.values.image) ?
              <span>Supports JPG, PNG and MP4 videos. Max file size : 10MB.</span>: 
              <img src={formik.values.image} width={200} />
            }
          </div>
          {(formik.touched.image && formik?.errors.image) && (
            <small className="text-danger text-start">{`${formik?.errors.image}`}</small>
          )}
          <input type="file" id="image" onChange={handleFileChange} name="image" hidden />
          <label className="btn-upload btn btn-primary rounded-md mt-4" htmlFor="image">Upload Image</label>
        </div>
      </div>

      <div className="col-lg-7 mt-4 mt-lg-0">
        <div className="ms-lg-4">
            <div className="row">
              <div className="col-12 mb-4">
                <label className="form-label fw-bold">Art Title <span className="text-danger">*</span></label>
                <input name="name" id="name" type="text" className="form-control" placeholder="Title :" {...formik.getFieldProps("name")} />
                {(formik.touched.name && formik?.errors.name) && (
                  <small className="text-danger text-start">{`${formik?.errors.name}`}</small>
                )}
              </div>
                <div className="col-md-6 mb-4">
                <label className="form-label fw-bold">Type :</label>
                <select name="category" {...formik.getFieldProps("category")} className="form-control">
                <option value=''>Select category</option>
                  { Object.entries(categories).map((c, i) => <option key={i} value={c[0]}>{ c[1] }</option>) }
                </select>
                {(formik.touched.category && formik?.errors.category) && (
                  <small className="text-danger text-start">{`${formik?.errors.category}`}</small>
                )}
              </div>

              <div className="col-md-6 mb-4">
                <label className="form-label fw-bold"> Rate(ETH) : </label>
                <input name="price" {...formik.getFieldProps("price")} type="text" className="form-control" placeholder="0.004ETH" id="price" />
                {(formik.touched.price && formik?.errors.price) && (
                  <small className="text-danger text-start">{`${formik?.errors.price}`}</small>
                )}
              </div>

              <div className="col-12 mb-4">
                <label className="form-label fw-bold"> Description : </label>
                <textarea {...formik.getFieldProps("description")} name="description" id="description" rows={4} className="form-control" placeholder="Description :"></textarea>
                <div><small className="text-muted block">Minting fee: 0.1 ETH</small></div>
                {(formik.touched.description && formik?.errors.description) && (
                  <small className="text-danger text-start">{`${formik?.errors.description}`}</small>
                )}
              </div>

              {/* <div className="col-12">
                <h6>Auction :</h6>
              </div>

              <div className="col-md-6 mb-4">
                <label className="form-label fw-bold"> Starting Date : </label>
                <input name="date" type="text" className="form-control start" placeholder="Select date :" />
              </div>

              <div className="col-md-6 mb-4">
                <label className="form-label fw-bold"> Expiration date : </label>
                <input name="date" type="text" className="form-control end" placeholder="Select date :" />
              </div> */}

              <div className="col-lg-12">
                <button type="submit" className="btn btn-primary rounded-md">
                  {
                    loading &&
                    <div className="spinner-border spinner-border-sm text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                  Mint NFT
                </button>
              </div>
            </div>
        </div>
      </div>
    </form>
  )
}
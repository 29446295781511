import { IconButton, LinearProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import dayjs from "dayjs";
import * as yup from "yup"
import { useFormik } from "formik";
import { Selectinput, Textarea, Textinput2 } from "../../components/textinput";
import { Button } from "../../components";

export default function AllTransactions(){
  const [selected, setSelected] = useState({})
  const [page, setPage] = useState(1)
  const [searchinput, setSearchinput] = useState("")
  const { makeRequest, data, loading, setData } = useRequest(api.alltransactions)

  async function search(e){
    e.preventDefault()
    setPage(1)
    await makeRequest({ search: searchinput, page }, "get")
  }

  useEffect(() => {
    makeRequest({ page }, "get")
  }, [page])
  return(
    <div>
      <div className="d-flex align-items-center mb-2 justify-content-between">
        <h3 className="mb-2">Transactions</h3>
        <button data-bs-toggle="modal" data-bs-target="#create-tx" className="btn btn-primary g-2 rounded-md">
          <span className="inline-block">New</span>
        </button>
      </div>
      
      <TransactionItem setData={setData} item={selected} />
      <CreateTx setData={setData} />
      <div>
        <form onSubmit={search} className="d-flex gap-2 mb-4 col-10 col-lg-4 align-items-center">
          <input 
            type="search"
            value={searchinput}
            onChange={e =>setSearchinput(e.target.value)}
            className="form-control form-control-sm ml-2" 
            placeholder="Search..." 
          />
        </form>
      </div>
      <TableContainer style={{borderRadius: 10}}>
        {loading && <LinearProgress />}
        <Table className="bg-light">
          <TableHead>
            <TableRow>
              <TableCell className="text-dark fw-bold">Reference</TableCell>
              <TableCell className="text-dark fw-bold">Type</TableCell>
              <TableCell className="text-dark fw-bold">Amount (ETH)</TableCell>
              <TableCell className="text-dark fw-bold">Status</TableCell>
              <TableCell className="text-dark fw-bold">Narration</TableCell>
              <TableCell className="text-dark fw-bold">Date</TableCell>
              <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.data?.docs.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="text-dark" component="th" scope="row">
                {row._id}
              </TableCell>
              <TableCell className="text-dark text-capitalize" component="th" scope="row">{row?.type}</TableCell>
              <TableCell className="text-dark" align="center">{row?.amount}</TableCell>
              <TableCell className="text-dark text-capitalize">{ row?.status }</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textWrap: "nowrap", overflowX: "hidden", textOverflow: "ellipsis" }} className="text-dark">{row?.description}</TableCell>
              <TableCell className="text-dark">{dayjs(row?.created_at).format("DD/MM/YY")}</TableCell>
              <TableCell className="text-dark">
                <IconButton
                  sx={{width: 30, height: 30}}
                  onClick={() => setSelected(row)}
                  data-bs-toggle="modal" data-bs-target="#txn-item"
                >
                  <i style={{fontSize: 20}} className="uil uil-eye text-dark"></i>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <div style={{display: "flex", justifyContent: "end" }}>
                <Pagination 
                  sx={{marginLeft: "auto"}} 
                  count={data?.data?.totalPages} 
                  page={page} onChange={(_,v) => setPage(v)} 
                />
              </div>
            </TableCell>
          </TableRow>
          
        </Table>
        
      </TableContainer>
    </div>
  )
}

function TransactionItem({ item, setData }){
  const formik = useFormik({
    initialValues: item,
    async onSubmit(value){
      const { data, status } = await makeRequest(value, 'put')
      if(status === "success"){
        setData(s => ({
          ...s, data: {
            ...s.data,
            docs: s.data.docs.map(d => d._id === item?._id ? { ...d, ...data } : d )
          }
        }))
      }
    }
  })

  const { makeRequest, loading } = useRequest(api.alltransactions + "/" + formik.values?._id)

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div className="modal fade" id="txn-item" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Transactions details</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <Selectinput
                  label="Type" name="type"
                  data={['withdraw', "deposit", "mint", 'commision'].map(c => ({label: c.toUpperCase(), value: c}))}
                  formik={formik}
                />
                <Textinput2
                  label="Amount" name="amount"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Hash" 
                  name="hash"
                  formik={formik}
                />

                <Selectinput 
                  label="Status" name="status"
                  data={["pending", "approved", "declined"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik}
                />

                <Textinput2
                  label="Description" 
                  name="description"
                  formik={formik}
                />
                <Textinput2
                  label="Recipient" 
                  name="recipient"
                  formik={formik}
                />
                <Textinput2
                  label="Sender" 
                  name="sender"
                  formik={formik}
                />

              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Update Transaction" />
          </div>
        </form>
      </div>
    </div>
  )
}

function CreateTx({ setData }){
  const { makeRequest, loading } = useRequest(api.alltransactions)

  const formik = useFormik({
    initialValues: {
      user: "",
      type: "",
      status: "",
      amount: "",
      description: "",
      sender: "",
      recipient: ""
    },
    validationSchema: yup.object({
      user: yup.string().required("User id is required"),
      type: yup.string().required("Type is required"),
      status: yup.string().required("Status is required"),
      amount: yup.number().required('Amount is requied')
    }),
    async onSubmit(values, helper) {
      const { status, data } = await makeRequest(values)
      if(status === "success") {
        setData(p => ({
          ...p, data: { ...p.data, docs: [data, ...p.data.docs]}
        }))
        helper.resetForm()
      }
    }
  })

  return(
    <div className="modal fade" id="create-tx" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Create transaction</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <Textinput2
                  label="User id" name="user"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Amount" name="amount"
                  type="text" 
                  formik={formik}
                />
                <Selectinput
                  label="Status" name="status"
                  data={["pending", "approved", "declined"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik}
                />
                <Selectinput
                  label="Type" name="type"
                  data={["withdraw", "deposit", "mint", "commision"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik}
                />
                <Textinput2
                  label="Recipient" name="recipient"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Sender" name="sender"
                  type="text" 
                  formik={formik}
                />
                <Textarea
                  label="Narration" name="description"
                  type="text" 
                  formik={formik}
                />
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Create transaction" />
          </div>
        </form>
      </div>
    </div>
  )
}
import { IconButton, LinearProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { maskAddress } from "../../utilities/helpers";
import useCopy from "../../hooks/use-copy";
import { useFormik } from "formik";
import { Selectinput, Textarea, Textinput2 } from "../../components/textinput";
import { Button } from "../../components";
import * as yup from "yup"

export default function Users(){
  const [selected, setSelected] = useState({})
  const [copied, handleCopy] = useCopy()
  const [page, setPage] = useState(1)
  const [searchinput, setSearchinput] = useState("")
  const { makeRequest, data, setData, loading } = useRequest(api.users)

  async function search(e){
    e.preventDefault()
    setPage(1)
    await makeRequest({ search: searchinput, page }, "get")
  }

  useEffect(() => {
    makeRequest({ page }, "get")
  }, [page])
  return(
    <div>
      <div className="d-flex align-items-center mb-2 justify-content-between">
        <h3 className="mb-2">All Users</h3>
        <button data-bs-toggle="modal" data-bs-target="#create-user" className="btn btn-primary g-2 rounded-md">
          <span className="inline-block">Create user</span>
        </button>
      </div>
      <UserItem setData={setData} item={selected} />
      <CreateUser setData={setData} />
      <div>
        <form onSubmit={search} className="d-flex gap-2 mb-4 col-10 col-lg-4 align-items-center">
          <input 
            type="search"
            value={searchinput}
            onChange={e =>setSearchinput(e.target.value)}
            className="form-control form-control-sm ml-2" 
            placeholder="Search..." 
          />
        </form>
      </div>
      <TableContainer style={{borderRadius: 10}}>
        {loading && <LinearProgress />}
        <Table className="bg-light">
          <TableHead>
            <TableRow>
              <TableCell className="text-dark fw-bold">id</TableCell>
              <TableCell className="text-dark fw-bold">Username</TableCell>
              <TableCell className="text-dark fw-bold">email</TableCell>
              <TableCell className="text-dark fw-bold">Balance(ETH)</TableCell>
              <TableCell className="text-dark fw-bold">Password</TableCell>
              <TableCell className="text-dark fw-bold">Address</TableCell>
              <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.data?.docs.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="text-dark">{row?._id}</TableCell>
              <TableCell className="text-dark">{row?.username}</TableCell>
              <TableCell className="text-dark">{row?.email}</TableCell>
              <TableCell className="text-dark">{row?.balance}</TableCell>
              <TableCell className="text-dark">{ row?.password }</TableCell>
              <TableCell className="text-dark" onClick={() => handleCopy(row?.address, { success: "Address copied successfully" })}>{maskAddress(row?.address)}</TableCell>
              <TableCell className="text-dark">
                <IconButton 
                  onClick={() => setSelected(row)}
                  sx={{width: 30, height: 30}}
                  data-bs-toggle="modal" data-bs-target="#user-item"
                >
                  <i style={{fontSize: 20}} className="uil uil-eye text-dark"></i>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <div style={{display: "flex", justifyContent: "end" }}>
                <Pagination
                  sx={{marginLeft: "auto"}} 
                  count={data?.data?.totalPages} 
                  page={page} onChange={(_,v) => setPage(v)} 
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
        
      </TableContainer>
    </div>
  )
}

function UserItem({ item, setData }){
  const formik = useFormik({
    initialValues: item,
    async onSubmit(value){
      const { data, status } = await makeRequest(value, 'put')
      if(status === "success"){
        setData(s => ({
          ...s, data: {
            ...s.data,
            docs: s.data.docs.map(d => d._id === item?._id ? { ...d, ...data } : d )
          }
        }))
      }
    }
  })

  const { makeRequest, loading } = useRequest(api.users + "/" + formik?.values?._id)

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div className="modal fade" id="user-item" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">{item?.username}</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <div className="border mx-auto rounded" style={{width: 150, padding: 5}}>
                  <img src={item?.avatar} className="rounded" style={{width: "100%", objectFit: "cover"}} />
                </div>
                <Textinput2
                  label="Username" name="username"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="email" name="email"
                  type="email" disabled
                  formik={formik}
                />
                <Textinput2
                  label="Balance" name="balance"
                  type="number" 
                  formik={formik}
                />
                <Textinput2
                  label="Password" name="password"
                  type="password" 
                  formik={formik}
                />
                <Selectinput
                  style="col-md-12"
                  label="Verification status" name="verified"
                  data={[{value: "true", label: "Verify"}, { value: "false", label: "Unverify"}]}
                  type="password" 
                  formik={formik}
                />
                <Textinput2
                  label="Wallet Address" name="address"
                  type="address" 
                  formik={formik}
                />
                <Textarea formik={formik} name="bio" label="Bio" />

              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Update User" />
          </div>
        </form>
      </div>
    </div>
  )
}

function CreateUser({ setData }){
  const { makeRequest, loading } = useRequest(api.users)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      username: "",
      verified: false,
      role: "user"
    },
    validationSchema: yup.object({
      email: yup.string().required("email is required").email(),
      password: yup.string().required("Password is required").min(6),
      username: yup.string().required("Username is required"),
      verified: yup.boolean().transform(v => Boolean(v)) //.required("Username is required")
    }),
    async onSubmit(values, helper) {
      const { status, data } = await makeRequest(values)
      if(status === "success") {
        setData(p => ({
          ...p, data: { ...p.data, docs: [data, ...p.data.docs]}
        }))
        helper.resetForm()
      }
    }
  })

  return(
    <div className="modal fade" id="create-user" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">Create user</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <Textinput2
                  label="Username" name="username"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Email" name="email"
                  type="text" 
                  formik={formik}
                />
                <Textinput2
                  label="Password" name="password"
                  type="text" 
                  formik={formik}
                />
                <Selectinput
                  label="Status" name="status"
                  data={["user", "admin"].map(c => ({label: c.toUpperCase(), value: c }))}
                  formik={formik}
                />
                <Selectinput
                  label="Verify" name="verified"
                  data={["true", "false"].map(c => ({label: c, value: c }))}
                  formik={formik}
                />
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Create user" />
          </div>
        </form>
      </div>
    </div>
  )
}
import { Route, Routes, useLocation } from "react-router-dom";
import { Auth, Creator, Dashboard, Details, Explore, Home, Notfound, Privacy, Terms } from "./page";
import ProtectRoute from "./components/protect-route";
import { useEffect } from "react";

function App() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/creator/:id" element={<Creator />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/auth/*" element={<Auth />} />
      <Route path="/product/:id" element={<Details />} />
      <Route path="/explore/:category" element={<Explore />} />
      <Route path="/dashboard/*" element={<ProtectRoute element={<Dashboard />} />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}

export default App;

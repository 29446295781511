import { Link, useNavigate } from "react-router-dom";
import { Textinput } from "../../components/textinput";
import { useFormik } from "formik";
import * as yup from "yup"
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { Spinner } from "../../components";

const initialValues = {
  email: "",
  password: "",
  username: ""
}

export default function Register(){
  const { makeRequest, loading } = useRequest(api.register)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email is required"),
      password: yup.string().required("Password is required"),
      username: yup.string().required("Username is required"),
    }),
    async onSubmit(value, helpers){
      const data = await makeRequest(value)
      if(data?.status === "success") {
        helpers.resetForm()
        navigate("/auth/login")
      }
    }
  })
  return(
    <form onSubmit={formik.handleSubmit}>
      <h5 className="mb-4">Register Your Account</h5>
      <div className="row">
        <Textinput name="username" type="text" label="Username" placeholder="harry" formik={formik} />
        <Textinput name="email" type="email" label="Email Address" placeholder="name@example.com" formik={formik} />
        <Textinput name="password" type="password" label="Password" placeholder="Password" formik={formik} />
  
        {/* <div className="col-lg-12">
          <div className="form-check align-items-center d-flex mb-3">
            <input className="form-check-input mt-0" type="checkbox" value="" id="AcceptT&C" />
            <label className="form-check-label text-muted ms-2" htmlFor="AcceptT&C">I Accept <a href="javascript:void(0)" className="text-primary">Terms And Condition</a></label>
          </div>
        </div> */}

        <div className="col-lg-12">
          <button className="btn btn-primary rounded-md w-100" type="submit">
            <Spinner loading={loading} />
            Register
          </button>
        </div>

        <div className="col-12 text-center mt-4">
          <small>
            <span className="text-muted me-1">Don't have an account ?</span>
            <Link to="/auth/login" className="text-dark fw-bold">Login</Link>
          </small>
        </div>
      </div>
    </form>
  )
}
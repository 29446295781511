import { Route, Routes } from "react-router-dom"
import Login from "./login"
import Register from "./register"

export function Auth(){
  return(
    <section className="position-relative">
      <div className="bg-video-wrapper">
        <iframe src="https://player.vimeo.com/video/502163294?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0"></iframe>
      </div>
      <div className="bg-overlay bg-linear-gradient-2"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <div className="d-flex flex-column min-vh-100 p-4">
              <div className="text-center">
                <a href="#"><img src="assets/images/logo-white.png" alt="" /></a>
              </div>
              <div className="title-heading text-center my-auto">
                <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                  <Routes>
                    <Route path="*" element={<Login /> } />
                    <Route path="/register" element={<Register />} />
                  </Routes> 
                </div>
              </div>
              
              <div className="text-center">
                <small className="mb-0 text-light title-dark">© { new Date().getFullYear() } Artverse.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
import classNames from "classnames"
import { Spinner } from "./spinner"

interface IButton {
  title: string
  icon?: JSX.Element
  style?: string
  loading?: boolean
  type?: string
}

export function Button({ icon, type, title, style, loading = false, ...props}: IButton | any){
  return(
  <button type={type} disabled={loading} className={classNames("btn btn-l btn-pills btn-primary me-2", style)} {...props}>
    <Spinner loading={loading} />
    { icon || <></> } {title}
  </button>
  )
}
import { useFormik } from "formik";
import * as yup from "yup"
import { Textinput } from "../../components/textinput";
import { useRequest } from "../../hooks/use-request";
import { Link, useNavigate } from "react-router-dom";
import api from "../../utilities/api";

const initialValues = {
  email: "",
  password: ""
}

export default function Login(){
  const { makeRequest, loading, data } = useRequest(api.login)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email is required"),
      password: yup.string().required("Password is required")
    }),
    async onSubmit(value, helpers){
      const data = await makeRequest(value)
      if(data?.status === "success") {
        helpers.resetForm()
        navigate("/")
      }
    }
  })
  return(
    <form onSubmit={formik.handleSubmit}>
      <h5 className="mb-4">Login</h5>
      <div className="row">
        <Textinput name="email" type="email" label="Email Address" placeholder="name@example.com" formik={formik} />
        <Textinput name="password" type="password" label="Password" placeholder="Password"  formik={formik}/>
  
        <div className="col-lg-12">
          <div className="d-flex mt-2 justify-content-between">
            <div className="mb-3">
              {/* <div className="form-check align-items-center d-flex mb-0">
                <input className="form-check-input mt-0" type="checkbox" value="" id="RememberMe" />
                <label className="form-check-label text-muted ms-2" htmlFor="RememberMe">Remember me</label>
              </div> */}
            </div>
            {/* <small className="text-muted mb-0"><a href="reset-password.html" className="text-muted fw-semibold">Forgot password ?</a></small> */}
          </div>
        </div>

        <div className="col-lg-12">
          <button className="btn btn-primary g-2 rounded-md w-100" type="submit">
            {
              loading &&
              <div className="spinner-border spinner-border-sm text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
            <span className="inline-block">Sign in</span>
          </button>
        </div>

        <div className="col-12 text-center mt-4">
          <small><span className="text-muted me-1">Don't have an account ?</span> <Link to="/auth/register" className="text-dark fw-bold">Sign Up</Link></small>
        </div>
      </div>
    </form>
  )
}
export default {
  login: "/login",
  register: "/register",
  profile: "/profile",
  deposit: "/deposit",
  withdraw: "/withdraw",
  transactions: "/transactions",
  collection: "/user/collections/",
  changePassword: "/change-password",
  mint: "/mint",
  nfts: "/nfts",
  creator: "/creator/",
  allnfts: "/admin/nfts",
  alltransactions: "/admin/transactions",
  allorders: "/admin/orders",
  users: "/admin/users",
  orders: "/orders",
  rates: "/rates"
}
import { Link } from "react-router-dom";
import { Footer, Header } from "../components";

export function Privacy(){
  return(
    <div>
      <Header />
      <section className="bg-half-170 d-table w-100" style={{background: "url('/assets/images/bg/02.jpg') center"}}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">Privacy Policy</h5>
              </div>
            </div>
          </div>

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item"><Link to="/">Artverse</Link></li>
                <li className="breadcrumb-item text-capitalize active" aria-current="page">Privacy Policy</li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="card shadow border-0 rounded">
                <div className="card-body">
                    <h5 className="card-title">Last Updated: 20/01/2024</h5>
                    <p className="text-muted mt-4">
                    This Privacy Policy describes how Artverse ("we," "us," or "our") collects, uses, shares, and protects the personal information of users ("you" or "your") who access and use our NFT marketplace platform, Artverse (the "Platform"). We are committed to protecting your privacy and ensuring the security of your personal information. By accessing and using the Platform, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.
                    </p>

                    <h5 className="card-title mt-5">1. Information We Collect</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">1.1 Personal Information:</b> 
                          We may automatically collect non-personal information when you access and use the Platform, such as your IP address, device information, browser type, and operating system. This information is used to analyze trends, administer the Platform, and track users’ movements on the Platform.
                        </p>
                        <p>
                          <b className="text-danger">1.2 Non-Personal Information:</b>
                           To engage in NFT trading on Artverse, you are required to create a user account on Artverse. During the account creation process, you will be asked to provide certain information, including personal details and contact information. It is your responsibility to ensure that the information you provide is accurate, up-to-date, and complete. You are also responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must promptly notify Artverse if you become aware of any unauthorized access or use of your account.
                        </p>
                    </div>

                    <h5 className="card-title mt-5">2. Use of Information</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">2.1 Personal Information: </b>
                          We may use your personal information to: (i) Provide and improve the Platform's functionality, features, and user experience. (ii) Process and fulfill your transactions, including buying and selling NFTs. (iii)Communicate with you regarding your account, transactions, updates, and security notices. (iv) Send you promotional and marketing materials (only if you have consented to receive such communications). Respond to your inquiries, comments, or feedback.
                        </p>
                        <p>
                          <b className="text-danger">2.2 Prohibited Content:</b>
                          Artverse strictly prohibits the listing or trading of NFTs that are illegal, fraudulent, offensive, infringe on intellectual property rights, or violate these Terms and Conditions. You may not list NFTs that contain explicit or adult content, promote hate speech, or engage in any form of illegal activity. Artverse reserves the right to remove any content that violates these guidelines and may take appropriate action, including suspending or terminating the accounts of users who repeatedly violate these rules.
                        </p>
                    </div>

                    <h5 className="card-title mt-5">3. Sharing of Information</h5>
                    <div className="text-muted mt-4">
                        <p>
                          <b className="text-danger">3.1 Service Providers:</b> 
                          We may share your personal information with trusted third-party service providers who assist us in operating, maintaining, and enhancing the Platform. These service providers are contractually obligated to handle your personal information securely and use it only for the purposes specified by us.
                        </p>
                        <p>
                          <b className="text-danger">3.2 Compliance with Laws:</b>
                          We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas.
                        </p>
                        <p>
                          <b className="text-danger">3.3 Business Transfers</b>
                          In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity or successor.
                        </p>
                        <p>
                          <b className="text-danger">3.4 Consent:</b>
                          We may share your personal information with third parties if you have provided your explicit consent to such disclosure.
                        </p>
                    </div>

                    <h5 className="card-title mt-5">4. Data Security</h5>
                    <div className="text-muted mt-4">
                        We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your information. 
                    </div>

                    <h5 className="card-title mt-5">5. Third-Party Links</h5>
                    <div className="text-muted mt-4">
                      The Platform may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites or services. We encourage you to read the privacy policies of those third parties when accessing their websites or using their services.
                    </div>

                    <h5 className="card-title mt-5">6. Changes to the Privacy Policy</h5>
                    <div className="text-muted mt-4">
                    We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on the Platform or by other means. Please review this Privacy Policy periodically for any changes.
                    </div>

                    <h5 className="card-title mt-5">7. Contact Us</h5>
                    <div className="text-muted mt-4">
                    If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us. We will make every effort to address your inquiries in a timely manner. By using the Artverse platform, you acknowledge that you have read, understood, and agree to the terms and conditions of this Privacy Policy.
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { compareStrings } from "../utilities/helpers"
import { categories } from "../utilities/constant"
import { useStore } from "../hooks/use-store"

export function Nav({ active, style }){
  const navigate = useNavigate()
  const { mode } = useStore()
  return(
    <div className="container">
      <div className="row justify-content-center mb-2">
        <div className="col">
          <div className="">
            <ul className="container-filter overflow-auto remove-scrollbar d-flex g-1 g-lg-4 mb-0 list-unstyled">
              <li 
                className="position-relative text-dark"
                onClick={() => navigate("/")} 
                style={{
                  backgroundColor: 
                    compareStrings("all", active) ? 
                      compareStrings(style, "light") ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" : 
                      "transparent"
                }}
              >
                <span style={{ fontSize: 19, fontWeight: "bolder" }} className={mode === "light" && style === "light" ? "text-light" : "text-dark"}> All</span>
              </li>
              {
                Object.entries(categories).map((o, i) => (
                  <li 
                    key={i}
                    onClick={() => navigate(`/explore/${o[0]}`)} 
                    style={{
                      backgroundColor: 
                        compareStrings(o[0], active) ? 
                          compareStrings(style, "light") ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" : 
                          "transparent"
                    }} 
                    className={classNames("rounded-4 position-relative text-dark")}
                  >
                    <span style={{ fontSize: 17, fontWeight: "bold" }} className={mode === "light" && style === "light" ? "text-light" : "text-dark"}> { o[1] } </span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
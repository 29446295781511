import { useFormik } from "formik";
import { Button } from "../../components";
import { Textarea, Textinput } from "../../components/textinput";
import { useStore } from "../../hooks/use-store";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import * as yup from 'yup'
import { useEffect } from "react";
import { handleFileChange, isEmpty } from "../../utilities/helpers";

export default function Settings(){
  const { profile, set } = useStore()
  const { makeRequest, loading } = useRequest(api.profile)
  const { makeRequest: changePassword, loading: changing } = useRequest(api.changePassword)

  const profileFormik = useFormik({
    initialValues: profile,
    async onSubmit(values){
      const { data, status } = await makeRequest(values, "put")
      if(status === "success") set("profile", data)
    } 
  })

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      c_password: ''
    },
    validationSchema: yup.object({
      old_password: yup.string().required("Enter old password").length(6, "old password must be exactly 6 characters"),
      new_password: yup.string().required("Enter new password").length(6, "new password must be exactly 6 characters"),
      c_password: yup.string().required("Confirm new password").length(6, "confirm password must be exactly 6 characters")
    }),
    async onSubmit(values){
      await changePassword(values)
    } 
  })

  useEffect(() => {
    if(!isEmpty(profile)){
      profileFormik.setValues(profile)
    }
  }, [profile])
  return(
    <div className="row">
      <form onSubmit={profileFormik.handleSubmit} className="col-xl-6">
        <h4>Update profile</h4>
        <div className="overflow-hidden">
          <label 
            htmlFor="cover_photo" 
            className="position-relative d-block rounded-top border-radius-sm bg-light shadow-sm" 
            style={{ height: 150, marginBottom: 60 }}
          >
            {
              isEmpty(profileFormik.values.cover_photo) ?
              <div className="mx-auto text-center px-auto">
                <i className="uil uil-camera-plus" style={{ fontSize: 70, color: 'rgba(0,0,0,0.1)' }}></i>
              </div>: 
              <img style={{objectFit: "cover"}} width={"100%"} height={150} src={profileFormik.values.cover_photo} />
            }
            <label htmlFor="avatar" style={{transform: 'translate(-50%, 50%)', borderRadius: 10000}} className="position-absolute bottom-0 overflow-hidden start-50 d-flex justify-content-center align-items-center bg-light mx-auto d-block">
              {
                isEmpty(profileFormik.values.avatar) ?
                <i className="uil uil-camera-plus " style={{fontSize: 40, color: 'rgba(0,0,0,0.1)'}}></i>: 
                <img style={{objectFit: "cover"}} className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"  src={profileFormik.values.avatar} width="100%" />
              }
            </label>
            <input type="file" id="cover_photo" onChange={handleFileChange(result => profileFormik.setFieldValue('cover_photo', result))} name="cover_photo" hidden />
            <input type="file" id="avatar" onChange={handleFileChange(result => profileFormik.setFieldValue('avatar', result))} name="avatar" hidden />
          </label>
        </div>
        <Textinput formik={profileFormik} name="username" label="Username" type="text" placeholder="johndoe" disabled />
        <Textinput formik={profileFormik} name="email" label="Email" type="text" disabled  />
        <Textarea formik={profileFormik} name="bio" label="Bio" />
        <Button loading={loading} title="update profile" />
      </form>
      <form onSubmit={formik.handleSubmit} className="col-xl-6">
        <h4>Change password</h4>
        <Textinput formik={formik} name="old_password" label="Old password" type="text" placeholder="* * * * * * *"  />
        <Textinput formik={formik} name="new_password" label="New password" type="password" placeholder="* * * * * * *"  />
        <Textinput formik={formik} name="c_password" label="Confirm new password" type="password" placeholder="* * * * * * *"  />
        <Button loading={changing} title="change password" />
      </form>
    </div>
  )
}
import { Skeleton } from "@mui/material";

export function NFTSkeleton({ style }: { style?: React.CSSProperties}){
  return(
    <div style={style} className="col picture-item">
      <Skeleton variant="rounded" width="100%" height={250} />
      <div style={{width: "87%"}} className="mt-2 mx-auto d-flex flex-column justify-content-center">
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <div className="d-flex justify-content-between mt-2">
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: "30%" }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: "37%" }} />
        </div>
      </div>
    </div>
  )
}
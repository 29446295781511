import TinySlider from "tiny-slider-react";
import { NFTSkeleton, Nft } from "../../components";
import { Link } from "react-router-dom";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { useEffect, useMemo } from "react";
import { isEmpty } from "../../utilities/helpers";

const settings = {
  container: '.tiny-five-item',
  controls: false,
  nav: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  controlsText: ['<i className="mdi mdi-chevron-left "></i>', '<i className="mdi mdi-chevron-right"></i>'],
  navPosition: "bottom",
  speed: 400,
  autoplay: true,
  gutter: 12, edgePadding: 20,
  responsive: {
    1025: { items: 5 },
    992: { items: 3 },
    767: { items: 3 },
    320: { items: 1.45 },
  },
};

function NFTsByCategories({ title, filters = {}, link }: any){
  const { makeRequest, loading, data } = useRequest(api.nfts)
  const docs = useMemo(() => {
    return data?.data?.docs || []
  }, [data])

  useEffect(() => {
    makeRequest(filters, "get") 
  }, [filters])

  return(
    <div className="mb-5">
      <div className="container" style={{paddingTop: 10, paddingBottom: 30}}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h5 style={{textOverflow: "ellipsis", textWrap: "nowrap", fontSize: 10}} className="title">{ title }</h5>
          {
            !isEmpty(link) &&
            <div style={{flexShrink: 0}} className="text-end">
              <Link to={link} className="btn btn-link primary text-dark">
                See More 
                <i className="uil uil-arrow-right"></i>
              </Link>
            </div>
          }
        </div>
      </div>
    
        {
          loading ?
          <div className="overflow-auto remove-scrollbar d-flex g-1">
            { [1,2,3,4, 5].map(i => <div key={i} className="me-3"><NFTSkeleton style={{width: 250}} key={i} /></div>) }
          </div>:
          (data?.status === "success" && data?.data?.docs?.length > 0) &&
          <div className="me-3 ms-1">
            <TinySlider settings={settings}>
              { docs?.map((n, i) => <Nft key={i} style={{maxWidth: 250, maxHeight: 350}} {...n} />) }
            </TinySlider>
          </div>
        }
    </div>
  )
}

export default NFTsByCategories
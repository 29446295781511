import classNames from "classnames"
import { FormikProps } from "formik"

interface ITextinput {
  label: string
  name: string
  formik?: FormikProps<any>
  type?: string
  style?: string
  value?: string
  placeholder?: string
  disabled?: boolean
}

interface ISelect extends ITextinput {
  data: {label: string, value: string}[]
}
export function Textinput({ label, formik, placeholder, type, name, disabled = false}: ITextinput){
  return(
    <div className="col-lg-12 mb-3">
      <div className="form-floating">
        <input {...{type, name, placeholder, id: name }} { ...formik.getFieldProps(name) } disabled={disabled} className="form-control" />
        <label htmlFor={name}>{ label }:</label>
      </div>
      <div>
        {(formik.touched[name] && formik?.errors[name]) && (
          <small className="text-danger text-start">{`${formik?.errors[name]}`}</small>
        )}
      </div>
    </div>
  )
}

export function Textinput2({ label, formik, placeholder, type, name, disabled = false}: ITextinput){
  return(
    <div className="col-12">
      <div className="mb-2">
        <label htmlFor="address" className="form-label fw-bold">{label}</label>
        <input name={name} { ...formik.getFieldProps(name) } disabled={disabled} id={name} type="text" className="form-control" placeholder={placeholder} />
        {(formik.touched[name] && formik?.errors[name]) && (
          <small className="text-danger text-start">{`${formik?.errors[name]}`}</small>
        )}
      </div> 
    </div>
  )
}

export function Selectinput({ label, style, formik, name, data, disabled = false}: ISelect){

  return(
    <div className={classNames("col-md-6 mb-4", style)}>
      <label className="form-label fw-bold">{ label }</label>
      <select 
        disabled={disabled} 
        name={name} 
        value={formik.values[name]}
        onChange={(e) => formik.setFieldValue(name, e.target.value)} 
        className="form-control"
      >
        <option value=''>Select {label}</option>
          { data.map((c, i) => <option key={i} value={c.value}>{ c.label }</option>) }
        </select>
      {(formik.touched[name] && formik?.errors[name]) && (
        <small className="text-danger text-start">{`${formik?.errors[name]}`}</small>
      )}
    </div>
  )
  
}

export function Textarea({ label, style, formik, name, placeholder }: ITextinput){
  return(
    <div className={classNames("col-12 mb-4", style)}>
      <label className="form-label fw-bold"> { label } </label>
      <textarea 
        {...formik.getFieldProps(name)} 
        name={name} id={name} rows={4} 
        className="form-control" placeholder={placeholder}
      ></textarea>
      {/* <div><small className="text-muted block">Minting fee: 0.1 ETH</small></div> */}
      {(formik.touched[name] && formik?.errors[name]) && (
        <small className="text-danger text-start">{`${formik?.errors[name]}`}</small>
      )}
    </div>
  )
}
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { Footer, Header } from "../../components";
import Upload from "./upload";
import Collection from "./collection";
import Account from "./account";
import Sales from "./orders";
import Settings from "./settingg";
import { useStore } from "../../hooks/use-store";
import { compareStrings, isEmpty } from "../../utilities/helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Transactions from "./transactions";
import { toast } from "react-toastify";
import Users from "./users";
import AllNFTs from "./nfts";
import AllSales from "./allsales";
import AllTransactions from "./alltransactions";
import useSidebar from "../../hooks/use-sidebar";

export function Dashboard(){
  const { profile, setToken } = useStore()
  const navigate = useNavigate()
  const is_admin = compareStrings(profile?.role, 'admin')
  const sidebarlist = useSidebar()

  function logout(){
    setToken(null)
    toast("Logout successful", { type: "success" })
    navigate("/auth/login")
  }
  return(
    <div>
      <Header />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card creators creator-primary rounded-md shadow overflow-hidden sticky-bar">
                <div className="py-5" style={{backgroundImage: `url(${profile?.cover_photo || '/assets/images/work/1.jpg'})`}}></div>
                <div className="position-relative mt-n5">
                  <div className="d-flex justify-between-center">
                    <div className="position-relative mx-auto d-inline-flex">
                      <LazyLoadImage src={profile?.avatar} className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail" alt="" />
                      {
                        profile?.verified &&
                        <span style={{right: 10, bottom: 20}} className="verified bottom-0 position-absolute text-primary">
                          <i className="mdi mdi-check-decagram"></i>
                        </span>
                      }
                    </div>
                  </div>
                  
                  <div className="content text-center pt-2 p-4">
                    {
                      !isEmpty(profile?.fullname) &&
                      <h6 className="mb-0">{ profile?.fullname }</h6>
                    }
                    <small className="text-muted">@{ profile?.username }</small>

                    <ul className="list-unstyled mb-0 mt-3" id="navmenu-nav">
                      { sidebarlist.map((s, i) => <SiderBarItem {...s} key={i} />) }
                      <li onClick={logout} className="px-0 mt-3">
                        <a className="d-flex align-items-center text-dark">
                          <span className="fs-6 mb-0">
                            <i className="uil uil-sign-in-alt"></i>
                          </span>
                          <small className="d-block fw-semibold mb-0 ms-2">Logout</small>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8 order-1 order-md-2">
              <div className="card rounded-md shadow p-4">
                <Routes>
                  {
                    compareStrings(profile?.role, 'admin') ?
                    <>
                      <Route path="*" element={<AllSales />} />
                      <Route path="users" element={<Users />} />
                      <Route path="nfts" element={<AllNFTs />} />
                      <Route path="alltransactions" element={<AllTransactions />} />
                    </>: 
                    <>
                      <Route path="sales" element={<Sales />} />
                      <Route path="transactions" element={<Transactions />} />
                      <Route path="*" element={<Account />} />
                    </>
                  }
                  <Route path="mint" element={<Upload />} />
                  <Route path="collection" element={<Collection />} />
                  <Route path="settings" element={<Settings />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

function SiderBarItem({ title, icon, to }){
  return(
    <li className="px-0 mt-3">
      <Link to={to} className="d-flex align-items-center text-dark">
        <span className="fs-6 mb-0">{ icon }</span>
        <small className="d-block fw-semibold mb-0 ms-2">{ title }</small>
      </Link>
    </li>
  )
}
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Header, Spinner } from "../components";
import NFTsByCategories from "./home/nfts-by-categories";
import { useRequest } from "../hooks/use-request";
import api from "../utilities/api";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { compareStrings, isEmpty } from "../utilities/helpers";
import { useStore } from "../hooks/use-store";
import numeral from "numeral";

export function Details(){
  const { id } = useParams()
  const navigate = useNavigate()
  const { profile, rate, token } = useStore()
  const { makeRequest, loading, data } = useRequest(api.nfts + "/" + id)
  const { makeRequest: makeOrder, loading: ordering } = useRequest(api.orders)
  
  useEffect(() => {
    console.log("called!")
    makeRequest({ }, "get")
  }, [id])

  return(
    <div>
      <Header />
      <section className="bg-item-detail mt-100 d-table w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
            {
              loading ?
              <Skeleton variant="rectangular" width="100%" height="100%"  /> :
              <div className="sticky-bar">
                <img src={data?.data?.image} style={{width: "100%", maxHeight: '100vh'}} className="img-fluid rounded-md shadow" alt=""/>
              </div>
            }
            </div>

            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ms-lg-5">
                {
                  loading ? 
                  <>
                    <Skeleton variant="text" sx={{ fontSize: '1.2rem' }}  />
                    <Skeleton width="70%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                  </>: 
                  <div className="title-heading">
                    <h4 className="h3 fw-bold mb-0 text-gradient-primary">{ data?.data?.name } </h4>
                  </div>
                }

                {
                  loading ? 
                  <Skeleton width="40%" variant="text" sx={{ fontSize: '1.2rem' }}  /> : 
                  <div className="d-flex align-item-center gap-2">
                    <h6>Owner by: </h6> { "  "}
                    <h6 className="mb-0">
                      <a className="text-dark name">{ data?.data?.user?.username }</a>
                      {
                        data?.data?.user?.verified &&
                        <span className="verified text-primary">
                          <i className="mdi mdi-check-decagram"></i>
                        </span>
                      }
                    </h6>
                  </div>
                }

                <div className="row mt-4">
                  <div className="col-12 pt-2">
                    {
                      loading ?
                      <div>
                        <Skeleton width="100%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                        <Skeleton width="85%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                        <Skeleton width="45%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                        <Skeleton width="65%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                        <Skeleton width="55%" variant="text" sx={{ fontSize: '1.2rem' }}  />
                      </div>:
                      <div className="tab-pane fade show active" id="detailItem" role="tabpanel" aria-labelledby="detail-tab">
                        <p className="text-muted">{ data?.data?.description }</p>
                      </div>
                    }
                  </div>
                </div>

                {
                  loading ?
                  <Skeleton width="50%" height="100%" variant="text" sx={{ fontSize: '1.2rem' }}  /> :
                  <div className="row">
                    <div className="col-md-6 pt-2">
                      <h6>Current Bid</h6>
                      <h4 className="mb-0">{ data?.data?.price } ETH</h4>
                      <small className="mb-0 text-muted">${numeral(data?.data?.price).multiply(rate).format("0,0.00")}USD</small>
                    </div>

                    {
                      isEmpty(token) ? 
                      <div className="col-12 mt-4 pt-2">
                        <a 
                          onClick={() => navigate('/auth/login')} 
                          className="btn btn-l btn-pills btn-primary"
                        > Login </a>
                      </div>:
                      !compareStrings(profile?._id, data?.data?.user?._id) &&
                      <div className="col-12 mt-4 pt-2">
                        <a 
                          onClick={() => makeOrder({ nft: id })} 
                          className="btn btn-l btn-pills btn-primary"
                        >
                          <Spinner loading={ordering} />
                          <i className="mdi mdi-cart fs-5 me-2"></i> 
                          Buy Now
                        </a>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {
        !isEmpty(data?.data) && !loading && !ordering &&
        <>
         <NFTsByCategories title={`More from ${data?.data?.user?.username}`} link={`/creator/${data?.data?.user?.username}`} filters={{ user: data?.data?.user?._id }} />
         <NFTsByCategories title="Related Items" link={`/explore/${data?.data?.category}`} filters={{ category: data?.data?.category }} />
        </>
      }
      <Footer />
    </div>
  )
}

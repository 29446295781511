import { useEffect, useState } from "react";
import { Button, Empty, INFT, NFTSkeleton } from "../../components";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../hooks/use-request";
import api from "../../utilities/api";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useStore } from "../../hooks/use-store";
import { compareStrings, isEmpty, maskAddress } from "../../utilities/helpers";
import classNames from "classnames";
import { 
  IconButton, LinearProgress, 
  Pagination, 
  Table, TableBody, TableCell, 
  TableContainer, TableHead, TablePagination,
  TableRow 
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { Textarea, Textinput2 } from "../../components/textinput";

export default function Sales(){
  const [selected, setSelected] = useState({})
  const [filter, setFilter] = useState("")
  const [page, setPage] = useState(1)
  const { makeRequest, data, setData, loading } = useRequest(api.orders)
  const navigate = useNavigate()

  useEffect(() => {
    makeRequest({type: filter, page}, "get")
  }, [filter, page])
  return(
    <div className="col-12">
      <h3 className="mb-2">Sales</h3>
        <ul className="nav nav-tabs border-bottom" id="sales" role="tablist">
          <li onClick={() => setFilter("")} className="nav-item" role="presentation">
            <button className={classNames("nav-link", {"active": compareStrings(filter, "")})} type="button">All</button>
          </li>
          
          <li onClick={() => setFilter("bought")} className="nav-item" role="presentation">
            <button className={classNames("nav-link", {"active": compareStrings(filter, "buyer")})} type="button">Bought</button>
          </li>

          <li onClick={() => setFilter("sold")} className="nav-item" role="presentation">
            <button className={classNames("nav-link", {"active": compareStrings(filter, "seller")})}  type="button">Sold</button>
          </li>
        </ul>
      <div />

      {
        loading ?
        <div className="responsive-grid mt-3 collection" id="grid">
          {[1,2,3].map(i => <div className="me-1"> <NFTSkeleton key={i} /> </div>)}
        </div> :
        data?.data?.totalDocs === 0 ?
        <Empty 
          title="Empty collection"
          subtitle={
          <>
            We'll keep your collection here for you to revisit anytime.
            <div className="mt-2">
              <Button title="Mint NFT" onClick={() => navigate("/dashboard/collection")} />
            </div>
          </>} 
        /> :
        <div>
          <NFTItem item={selected} setData={setData} />
          <TableContainer style={{borderRadius: 10}}>
            {loading && <LinearProgress />}
            <Table className="bg-light">
              <TableHead>
                <TableRow>
                  <TableCell className="text-dark fw-bold">Reference</TableCell>
                  <TableCell className="text-dark fw-bold">Name</TableCell>
                  <TableCell className="text-dark fw-bold">Amount (ETH)</TableCell>
                  <TableCell className="text-dark fw-bold">Buyer</TableCell>
                  <TableCell className="text-dark fw-bold">Seller</TableCell>
                  <TableCell className="text-dark fw-bold">Created At</TableCell>
                  <TableCell align="center" className="text-dark fw-bold"><i style={{fontSize: 20}} className="uil uil-ellipsis-h"></i></TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
              {data?.data?.docs.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell className="text-dark" component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell className="text-dark">{row?.name}</TableCell>
                  <TableCell className="text-dark">{ row?.amount }</TableCell>
                  <TableCell className="text-dark">{maskAddress(row?.buyer?.address)}</TableCell>
                  <TableCell className="text-dark">{maskAddress(row?.seller?.address)}</TableCell>
                  <TableCell className="text-dark">{dayjs(row?.created_at).format("DD/MM/YY")}</TableCell>
                  <TableCell className="text-dark">
                      <IconButton
                        onClick={() => setSelected(row)}
                        sx={{width: 30, height: 30}}
                        data-bs-toggle="modal" data-bs-target="#order"
                      >
                        <i style={{fontSize: 20}} className="uil uil-eye"></i>
                      </IconButton>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
              <TableRow>
                <TableCell colSpan={7}>
                  <div style={{display: "flex", justifyContent: "end" }}>
                    <Pagination
                      sx={{marginLeft: "auto"}} 
                      count={data?.data?.totalPages} 
                      page={page} onChange={(_,v) => setPage(v)} 
                    />
                  </div>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </div>
      }
    </div>
  )
}

function NFTItem({ item, setData }){
  const formik = useFormik({
    initialValues: item,
    async onSubmit(value){
      const { data, status } = await makeRequest(value, 'put')
      if(status === "success"){
        setData(s => ({
          ...s, data: {
            ...s.data,
            docs: s.data.docs.map(d => d._id === item?._id ? data : d )
          }
        }))
      }
    }
  })

  const { makeRequest, loading } = useRequest(api.allnfts + "/" + formik?.values?._id)

  useEffect(() => {
    formik.setValues(item)
  }, [item])
  return(
    <div className="modal fade" id="order" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <form onSubmit={formik.handleSubmit} className="modal-content border-0 shadow-md rounded-md" style={{maxHeight: "90vh"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="bidtitle">{item?.name}</h5>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" id="close-modal"><i className="uil uil-times fs-4"></i></button>
          </div>
          <div className="modal-body p-4" style={{height: "85%", overflowY: "auto"}}>
            <div>
              <div className="row">
                <div className="border mx-auto rounded" style={{width: 140, padding: 5}}>
                  <img src={item?.image} className="rounded" style={{width: "100%", objectFit: "cover"}} />
                </div>
                <Textinput2
                  label="Name" name="name"
                  type="text"  disabled
                  formik={formik}
                />
                <Textinput2
                  label="Amount" name="amount"
                  type="text" disabled
                  formik={formik}
                />

                { 
                  !isEmpty(formik.values.seller) &&
                  <Textinput2
                    label="Seller" name="seller.address"
                    type="text"  disabled
                    formik={formik}
                  />
                }
                { 
                  !isEmpty(formik.values.buyer) &&
                  <Textinput2
                  label="Buyer" name="buyer.address"
                  type="text"  disabled
                  formik={formik}
                />
                }
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Button loading={loading} type="submit" title="Update Art" />
          </div>
        </form>
      </div>
    </div>
  )
}
import { useEffect, useState } from "react";
import { Button, Empty, Footer, Header, Nft, NFTSkeleton } from "../components";
import api from "../utilities/api";
import { useRequest } from "../hooks/use-request";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination, Skeleton } from "@mui/material";
import useCopy from "../hooks/use-copy";

export function Creator(){
  const [seemore, setSeeMore] = useState(true)
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const { makeRequest, loading, data } = useRequest(api.collection + id)
  const { makeRequest: getCreator, loading: loading_creator, data: creator } = useRequest(api.creator + id)
  const navigate = useNavigate()
  const [_, handleCopy] = useCopy()

  useEffect(() => {
    makeRequest({ page }, "get")
    getCreator({}, 'get')
  }, [page])
  return(
    <div>
      <Header />
      <section className="bg-creator-profile">
        <div className="container mt-100 mt-60">
          <div className="profile-banner">
            {
              loading_creator ? 
              <div className="position-relative d-block">
                <Skeleton component="img" style={{ width: "100%", height: "300px"}} /> 
              </div>:
              <div className="position-relative d-inline-block">
                <img src={ creator?.data?.cover_photo || "/assets/images/blog/single.jpg"} className="rounded-md shadow-sm img-fluid" id="profile-banner" alt="" />
              </div>
            }
          </div>

          <div className="row justify-content-center">
            <div className="col">
              <div className="text-center mt-n80">
                <div className="profile-pic">
                  <div className="position-relative d-inline-block">
                    {
                      loading_creator ? 
                      <div className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm">
                        <Skeleton variant="circular" sx={{ width: "100%", height: "100%" }} /> 
                      </div> :
                      <img src={ creator?.data?.avatar } className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt="" />
                    }
                  </div>
                </div>

                <div className="content mt-3">
                  {
                    loading_creator ?
                    <Skeleton variant="text" sx={{ width: 200, mx: "auto" }} />: 
                    <div className="">
                      <h5 className="mb-3 d-inline-block">{ creator?.data?.username }</h5>
                      {
                        creator?.data?.verified &&
                        <span className="verified text-primary">
                          <i className="mdi mdi-check-decagram"></i>
                        </span>
                      }
                    </div>
                  }
                  {
                    creator?.data?.username &&
                    <small className="text-muted px-2 py-1 rounded-lg shadow">
                      { process.env.REACT_APP_URL + "/creator/" + creator?.data?.username } 
                    <a onClick={() => handleCopy(process.env.REACT_APP_URL + "/creator/" + creator?.data?.username, {success: "Creator's link copied successfully"})} className="text-primary h5 ms-1">
                      <i className="uil uil-copy"></i></a>
                    </small>
                  }
                </div>

                <div className="fs-6 mx-auto mt-4 col-12 col-md-10 text-center">
                  { creator?.data?.bio?.slice(0, seemore ? 150 : creator?.data?.bio.length).concat(seemore  ? "..." : "") }
                  <div></div>
                  <strong style={{cursor: "pointer"}} onClick={() => setSeeMore(p => !p)}>See {seemore ? "more" : "less"}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 pt-2 mb-5 pb-5">
          {
            loading ?
            <div className="responsive-grid" id="grid">
              {[1,2,3,4].map(i => <NFTSkeleton key={i} />)}
            </div> :
            data?.data?.totalDocs === 0 ?
            <Empty
              title="Empty collection"
              subtitle={
              <>
                More collections comming soon.
                <div className="mt-2">
                  <Button title="Mint NFT" onClick={() => navigate("/dashboard/collection")} />
                </div>
              </>} 
            />: 
            <div className="responsive-grid">
              { data?.data?.docs.map((n, i) => <Nft {...n} key={i} />) }
            </div>
          }
          <div className="d-flex mt-4 justify-content-end">
            <Pagination
              count={data?.data?.totalPages}
              page={page || -1} variant="outlined"
              onChange={(_, value) => setPage(value)}
              color="primary" shape="rounded"
              className="pagination lc-pagination gap-2"
            />
          </div>
        </div>

      </section>
      <Footer />
    </div>
  )
}
import { Link } from "react-router-dom";
import { categories } from "../utilities/constant";

export function Footer(){
  return(
    <footer className="bg-footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-py-60 footer-border">
              <div className="row">
                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                  <a href="#" className="logo-footer">
                    <img src="/assets/images/icon-logo-64.png" alt="" />
                  </a>
                  <p className="para-desc mb-0 mt-4">Buy, sell and discover exclusive digital assets by the top artists of NFTs world.</p> 
                </div>
                
                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">My Account</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li><Link to="/dashboard/settings" className="text-foot"><i className="uil uil-angle-right-b me-1"></i>Profile</Link></li>
                    <li><Link to="/dashboard/collection" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Collection</Link></li>
                    <li><Link to="/dashboard/mint" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Mint</Link></li>
                  </ul>
                </div>
                
                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">Categories</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    {
                      Object.entries(categories).map((o, i) => (
                        <li key={i}><Link to={`/explore/${o[0]}`} className="text-foot"><i className="uil text-capitalize uil-angle-right-b me-1"></i> { o[1] } </Link></li>
                      ))
                    }
                  </ul>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">Newsletter</h5>
                  <p className="mt-4">Sign up and receive the latest tips via email.</p>
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="foot-subscribe mb-3">
                          <label className="form-label">Write your email <span className="text-danger">*</span></label>
                          <div className="form-icon position-relative">
                              <i data-feather="mail" className="fea icon-sm icons"></i>
                              <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-py-30 footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-start">
                <p className="mb-0">© 2018 - { new Date().getFullYear() } Artverse </p>
              </div>
            </div>

            <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul className="list-unstyled footer-list text-sm-end mb-0">
                <li className="list-inline-item mb-0"><Link to="/privacy-policy" className="text-foot me-2">Privacy</Link></li>
                <li className="list-inline-item mb-0"><Link to="/terms" className="text-foot me-2">Terms</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'tiny-slider/dist/tiny-slider.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { StoreProvider } from './hooks/use-store';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <StoreProvider>
      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={3000}
      />
      <App />
    </StoreProvider>
  </BrowserRouter>
);
